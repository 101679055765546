import React from "react";
import { Link, useLocation } from "react-router-dom";

const Vehicle = ({ vehicle  }) => {
  const vehicleMakeModel = vehicle?.makeAndModel;
  const reportedHours = vehicle?.reported.hours;
  const vehicleRegistrationNumber = vehicle?.vehicleRegistrationNumber || vehicle?.veh_registration_no;
  const reportedStatusBgColor = reportedHours > 168 ? "bg-blue-500" : reportedHours < 24 ? "bg-green-500" : reportedHours > 24 && reportedHours < 48 ? "bg-yellow-500" : "bg-red-500";
  const vehicleImage = vehicle?.image || "/vehicle_placeholder.jpg";
  const location = useLocation();
  //console.log({ vehicle });
  return (
    <div className="vehicle-card">
      <img className="object-cover object-center w-full h-48" src={vehicleImage} width={32} height={32} alt="vehicle" />

      <div className="flex flex-col p-4 ">
        <h3>
          {vehicleMakeModel}
          <span className="mb-4 text-sm">Reg: {vehicleRegistrationNumber}</span>
        </h3>

        <Link to={`/vehicles/${vehicle?.id.toString()}`} state={{ from: location }}>
          <span className="w-full mt-2 btn-primary">View Details</span>
        </Link>
      </div>

      {/* availability status */}
      <div className="reported-status">
        <div className={`animate-pulse w-5 h-5 rounded-full ${reportedStatusBgColor}`}></div>
      </div>
    </div>
  );
};

export default Vehicle;
