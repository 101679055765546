import {api} from "./api";

export const vehicleMaintenanceProvidersApi = api.injectEndpoints({
  endpoints: builder => ({
    getAllVehicleMaintenanceProviders: builder.query({
      query: () => "/vehicleMaintenanceProviders",
      keepUnusedDataFor: 5
    }),
    getVehicleMaintenanceProvider: builder.query({
      query: id => `/vehicleMaintenanceProviders/${id}`
    })
  })
});

export const {useGetAllVehicleMaintenanceProvidersQuery, useGetVehicleMaintenanceProviderQuery} = vehicleMaintenanceProvidersApi;
