import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsDisplayLiveOnlyFeature, getIsDisplayLiveOnlyFeature } from "../../features/settings/settingsSlice";
import { Layout, NoRecords, OnError } from "../../components";
import { Link } from "react-router-dom";
import DataCard from "../../components/dashboard/DataCard";
import GoogleMap from "../../components/maps/google";
import Constant from "../../constant";
import { isValidArrayWithData } from "../../core/utilities";
import SystemFeatures from "../../constant/systemFeatures";
import Api from "../../api";
import ToggleButton from "../../components/toggle/ToggleButton";


const cameraFeedsMaxDaysToInclude = Constant.System.Dashboard.CameraFeeds.MaximumDaysToInclude;
const liveFeedMaxDaysToInclude = Constant.System.Dashboard.Realtime.MaximumDaysToInclude;

function Dashboard() {

  const { data: vehiclesRealtimeInfo = [], isLoading, isError, error } = Api.vehicles.useGetAllRealtimeVehiclesQuery({ cameraFeedsMaxDaysToInclude, liveFeedMaxDaysToInclude }, { pollingInterval: Constant.System.Dashboard.Polling.Interval });
  const { data: breakDownsCount } = Api.hubspotTickets.useGetActiveBreakdownTicketsCountQuery(undefined, { skip: !vehiclesRealtimeInfo || vehiclesRealtimeInfo.length === 0 });
  const vehiclesRealtimeDataFound = isValidArrayWithData(vehiclesRealtimeInfo?.vehicles);


  //console.log({isOldDisplay, isLoading,isError,error,isFetching,vehiclesRealtimeInfo,refetch });
  // console.log("Dashboard :)", {when: new Date().getTime(), isError})

  return isError ? <OnError error={error} /> :
    isLoading ? <DisplayRealtimeData vehiclesRealtimeInfo={{ vehicles: [] }} breakDownsCount={0} isLoading={isLoading} />
      : vehiclesRealtimeDataFound ? <DisplayRealtimeData vehiclesRealtimeInfo={vehiclesRealtimeInfo} breakDownsCount={breakDownsCount} />
        : <NoRecords title="Vehicles Realtime" message="No Vehicles Realtime Information found." />;
}

function DisplayRealtimeData({ vehiclesRealtimeInfo, breakDownsCount, isLoading = false }) {
  const currentIsDisplayLiveOnlyData = useSelector(getIsDisplayLiveOnlyFeature(SystemFeatures.DASHBOARD));
  const { vehicles: vehiclesRealtimeData = [], vehiclesWithLiveFeed, vehiclesWithCameraFeed } = vehiclesRealtimeInfo;

  const [displayLiveOnlyData, setDisplayLiveOnlyData] = useState(currentIsDisplayLiveOnlyData);
  const [displayVehiclesRealtimeData, setDisplayVehiclesRealtimeData] = useState(vehiclesRealtimeData);
  const [isChecked, setIsChecked] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {

    if (displayLiveOnlyData) {

      setDisplayVehiclesRealtimeData(vehiclesRealtimeData.filter(vehicleData => {

        //return vehicleData.last_reported_hours > -1 && vehicleData.last_reported_hours <= Constant.System.Dashboard.Realtime.LiveOnlyDataHoursToInclude;
        //changed to now use reported.hours
        return vehicleData.reported.hours > -1 && vehicleData.reported.hours <= Constant.System.Dashboard.Realtime.LiveOnlyDataHoursToInclude;
      }));
    }
    else {
      setDisplayVehiclesRealtimeData(vehiclesRealtimeData);
    }
    dispatch(setIsDisplayLiveOnlyFeature({ name: SystemFeatures.DASHBOARD, isDisplayLiveOnly: displayLiveOnlyData }));

  }, [dispatch, displayLiveOnlyData, vehiclesRealtimeData])

  //console.log({displayLiveOnlyData,currentIsDisplayLiveOnlyData, displayVehiclesRealtimeData});
  return (
    <Layout isLoading={isLoading} loadingText="Loading Realtime Data" >
      <header className="flex flex-row pr-4 rounded-sm shadow-lg bg-other col-span-full xl:col-span header-justify-between">
        <div className="flex flex-row items-center gap-2 px-5 py-4 font-semibold text-primary">
          <h2 className="font-semibold text-primary">Real-Time Data</h2>
        </div>
        <div className="flex justify-center">
          <label className="relative inline-flex items-center cursor-pointer">
            <span className="mr-3 text-sm font-medium text-primary">Display Live Vehicles Only</span>
            <ToggleButton onToggle={() => setDisplayLiveOnlyData(!displayLiveOnlyData)} isChecked={displayLiveOnlyData} />
          </label>
        </div>

      </header>

      <section className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
        {breakDownsCount > 0 ? <Link to={breakDownsCount > 0 ? "/services/repairs-maintenance" : ""}>
          <DataCard name={"Vehicle Health Issues"} value={breakDownsCount} icon={"vehicleHealth"} hasLink={breakDownsCount > 0} />
        </Link> : null}

        {vehiclesWithLiveFeed > 0 ? <Link to={vehiclesWithLiveFeed > 0 ? "/dashboard/live-data-feed" : ""}>
          <DataCard name={"Live Data Feed"} value={vehiclesWithLiveFeed} icon={"dataFeed"} hasLink={vehiclesWithLiveFeed > 0} />
        </Link> : null}

        {vehiclesWithCameraFeed > 0 ? <Link to={vehiclesWithCameraFeed > 0 ? "/dashboard/camera-feeds" : ""}>
          <DataCard name={"Dash Cam Feeds"} value={vehiclesWithCameraFeed} icon={"cameraFeed"} hasLink={vehiclesWithCameraFeed > 0} />
        </Link> : null}
        {/* This is currently disabled by Mohau: will be enabled once we have relevant data available
        <DataCard name={'Monthly Fuel Consumption'} value={'30,000L'} icon={'fuelConsumption'} />
        */}
      </section>
      {/*
      <section className="grid gap-4 lg:grid-cols-2">
        This is currently disabled by Mohau: will be enabled once we have relevant data available
        <TotalVehicles />
        <VehicleCondition />
       
      </section>
       */}

      <section className="p-0 h-88 section-container ">
        <div>
          <GoogleMap vehicles={displayVehiclesRealtimeData} />
        </div>
      </section>
    </Layout>
  );
}

export default Dashboard;
