import {api} from "./api";

export const userRolesApi = api.injectEndpoints({
  endpoints: builder => ({
    getUserRole: builder.query({
      query: emailAddress => `/userRoles/${emailAddress}`
    })
  })
});

export const {useGetUserRoleQuery} = userRolesApi;
