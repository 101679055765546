// import { TrendingUp } from "lucide-react"
import { CartesianGrid, LabelList, Line, LineChart, XAxis,Bar, BarChart,YAxis } from "recharts"

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../UI/card"
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent} from "../../UI/chart"


export const description = "A line chart with a label"




export function Top10LineChart({data, title, subtitle, xDataKey, yDataKey,lineColor}) {
    const chartConfig = {
        [xDataKey]: {
          label: xDataKey,
          color: "#2563eb",
        }
      } 
  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{subtitle}</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <LineChart
            accessibilityLayer
            data={data}
            margin={{
              top: 20,
              left: 20,
              right: 12,
            }}
          >
            <CartesianGrid vertical={false} opacity={0.2} />
         <XAxis
              dataKey={xDataKey}
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tick={{ fill:'var(--color-primary)' }}
            /> 
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator="line" labelKey={yDataKey} nameKey={xDataKey}  />}
            />
          <Line
              dataKey={yDataKey}
              type="natural"
              stroke={lineColor}
              strokeWidth={2}
              dot={{fill: lineColor}}
              activeDot={{r: 6}}
            >
              <LabelList
                position="top"
                offset={12}
                className="fill-primary"
                fontSize={12}
              />
            </Line>
          </LineChart>
        </ChartContainer>
      </CardContent>
    </Card>
  )
}

  export function Top10BarChart({data,xDataKey, yDataKey, title, subtitle, barColor, leftMargin=60, yTickWidth=100}) {
    const chartConfig = {
        [xDataKey]: {
          label: xDataKey,
          color: "#2563eb",
        }
      } 
    return (
      <Card>
        <CardHeader>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{subtitle}</CardDescription>
        </CardHeader>
        <CardContent>
          <ChartContainer  config={chartConfig} >
            <BarChart
              accessibilityLayer
              data={data}
              layout="vertical"
              margin={{
                left: leftMargin,
              }}
            >
              <YAxis
               dataKey={yDataKey}
                type="category"
                tickLine={false}
                tickMargin={10}
                axisLine={false}
                tickFormatter={(value) => `${value}`}
                tick={{ fill:'var(--color-primary)', width: yTickWidth, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              />
              <XAxis dataKey={xDataKey} type="number" hide />
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent labelKey={yDataKey} nameKey={xDataKey} />}
              />
              <Bar
                dataKey={xDataKey}
                // layout="vertical"
                fill={barColor}
                radius={5}
              >
                {/* <LabelList
                  dataKey={yDataKey}
                  position="insideLeft"
                  offset={8}
                  className="fill-[--color-label]"
                  fontSize={12}
                />
                <LabelList
                  dataKey={xDataKey}
                  position="right"
                  offset={8}
                  className="fill-foreground"
                  fontSize={12}
                /> */}
              </Bar>
            </BarChart>
          </ChartContainer>
        </CardContent>
      
      </Card>
    )
  }
