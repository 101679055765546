import { isBoolean } from "lodash";

export type FilterOption = {
  name: string,
  label: string,
  placeholder: string,
  allowMultiples?: boolean,
  isRiiotUseOnly?: boolean,
  selectionOptions?: object
  value?: string[] | string | null
  isDateTime?: boolean,
  startValue?: string|number,  
  endValue?: string | number,
  startDate?:  Date,
  endDate?: Date
 
}
export default class FilterOptions {
  driverOptions = {
    filters: [
      {
        name: "full_name",
        label: "Full Name",
        placeholder: "select full name",
        allowMultiples: true
      },
      {
        name: "identity_number",
        label: "Identity Number",
        placeholder: "select identity number"
      },
      {
        name: "company_number",
        label: "Company Number",
        placeholder: "select company number"
      },
      {
        name: "location",
        label: "Location",
        placeholder: "Location",
        allowMultiples: true
      }
    ]
  };
  deviceOptions = {
    filters: [
      {
        name: "id",
        label: "Device Id",
        placeholder: "select device id",
        allowMultiples: true
      },
      {
        name: "hardware_version",
        label: "Hardware Version",
        placeholder: "select hardware version",
        allowMultiples: true
      },
      {
        name: "serial_number",
        label: "Serial Number",
        placeholder: "select serial number",
        allowMultiples: true
      },
      {
        name: "primary_sim_iccid",
        label: "Prim Sim IccId",
        placeholder: "select sim iccid",
        allowMultiples: true
      },
      {
        name: "primary_sim_msisdn",
        label: "Prim Sim Msisdn",
        placeholder: "select sim msisdn",
        allowMultiples: true
      },
      {
        name: "manufacturer_api_activity_status",
        label: "Device Status",
        placeholder: "select device status",
        allowMultiples: true
      },
      {
        name: "veh_registration_no",
        label: "Vehicle Reg",
        placeholder: "select vehicle registration",
        allowMultiples: true
      },
      {
        name: "vehicle_is_in_for_repair",
        label: "In For Repairs",
        placeholder: "select in-for-repairs vehicles",
        isBoolean: true,
        allowMultiples: false
      },
      {
        name: "vehicles.is_marked_for_deletion",
        label: "Marked for Deletion",
        placeholder: "select marked-for-deletion vehicles",
        isBoolean: true,
        allowMultiples: false
      },
      {
        name: "firmware_name",
        label: "Firmware Version",
        placeholder: "select firmware version",
        allowMultiples: true
      },      
      {
        name: "lastReportedCategoryInfoLabel",
        label: "Last Reported Category",
        placeholder: "select last reported Category",
        allowMultiples: true
      },
      {
        name: "lastDataTimestampCategoryInfoLabel",
        label: "Data Timestamp Category",
        placeholder: "select data timestamp Category",
        allowMultiples: true
      }
    ]
  };

  deviceEventOptions = {
    filters: [
      {
        name: "event_type",
        label: "Event Type",
        placeholder: "select event type",
        allowMultiples: true
      },
      {
        name: "device_type",
        label: "Device Type",
        placeholder: "select device type",
        allowMultiples: true
      },
      {
        name: "veh_registration_no",
        label: "Vehicle Reg",
        placeholder: "select vehicle registration",
        allowMultiples: true
      },
      {
        name: "make",
        label: "Make",
        placeholder: "select make",
        allowMultiples: true
      },
      {
        name: "model",
        label: "Model",
        placeholder: "select model",
        allowMultiples: true
      },
 /*     {
        name: "data_timestamp",
        label: "Data Timestamp",
        placeholder: "select Date Range",
        allowMultiples: true,
        isDateTime: true
      }*/
    ]
  };

  simCardOptions = {
    filters: [
      {
        name: "iccid",
        label: "IccId",
        placeholder: "select sim IccId",
        allowMultiples: true
      },
      {
        name: "imsi",
        label: "Imsi",
        placeholder: "select sim Imsi",
        allowMultiples: true
      },
      {
        name: "msisdn",
        label: "Msisdn",
        placeholder: "select sim Msisdn",
        allowMultiples: true
      },
      {
        name: "ip_address",
        label: "IP Address",
        placeholder: "select sim IP Address",
        allowMultiples: true
      },
      {
        name: "network_status",
        label: "Network Status",
        placeholder: "select sim Network Status",
        allowMultiples: true
      }

    ]
  };

  simCardUsageLogsOptions = {
    filters: [
      {
        name: "column_name",
        label: "Change",
        placeholder: "select usage Change",
        allowMultiples: false
      },
     

    ]
  };

  vehicleOptions = {
    filters: [
      {
        name: "veh_registration_no",
        label: "Registration No",
        placeholder: "select vehicle registration",
        allowMultiples: true
      },
      {
        name: "make",
        label: "Make",
        placeholder: "select make",
        allowMultiples: true
      },
      {
        name: "model",
        label: "Model",
        placeholder: "select model",
        allowMultiples: true
      },
      {
        name: "vehicle_type",
        label: "Vehicle Type",
        placeholder: "select vehicle type",
        allowMultiples: true
      },
      {
        name: "colour",
        label: "Vehicle Colour",
        placeholder: "select colour",
        allowMultiples: true
      },
      {
        name: "year",
        label: "Year Model",
        placeholder: "select year",
        allowMultiples: true
      },
      {
        name: "device_id",
        label: "Device Id",
        placeholder: "select device id",
        allowMultiples: true,
        isRiiotUseOnly: true
      },
      {
        name: "is_immobile",
        label: "Is Immobile",
        placeholder: "select mobility",
        allowMultiples: false,
        isRiiotUseOnly: true
      }
    ]
  };

  vehicleBatteryInfoOptions = {
    filters: [
      {
        name: "battery_capacity",
        label: "Battery Capacity",
        placeholder: "select battery capacity",
        allowMultiples: true
      },
      {
        name: "veh_registration_no",
        label: "Registration No",
        placeholder: "select vehicle registration",
        allowMultiples: true
      },
      {
        name: "make",
        label: "Make",
        placeholder: "select make",
        allowMultiples: true
      },
      {
        name: "model",
        label: "Model",
        placeholder: "select model",
        allowMultiples: true
      },
      {
        name: "vehicle_type",
        label: "Vehicle Type",
        placeholder: "select vehicle type",
        allowMultiples: true
      },
      {
        name: "year",
        label: "Year Model",
        placeholder: "select year",
        allowMultiples: true
      }
    ]
  };

  cameraFeedsOptions = {
    filters: [
      {
        name: "veh_registration_no",
        label: "Registration No",
        placeholder: "select vehicle registration",
        allowMultiples: true
      },
      {
        name: "makeAndModel",
        label: "Make And Model",
        placeholder: "select make and model",
        allowMultiples: true
      }
    ]
  };

  servicesRepairAndMaintenanceOptions = {
    filters: [
      {
        name: "vehicle_registration",
        label: "Registration No",
        placeholder: "select vehicle registration",
        allowMultiples: true
      },
      {
        name: "makeAndModel",
        label: "Make And Model",
        placeholder: "select make and model",
        allowMultiples: true
      }
    ]
  };

  users = {
    filters: [
      {
        name: "email_address",
        label: "Email Address",
        placeholder: "select email address",
        allowMultiples: true
      },
      {
        name: "first_name",
        label: "First Name",
        placeholder: "select first name",
        allowMultiples: true
      },
      {
        name: "last_name",
        label: "Last Name",
        placeholder: "select last name",
        allowMultiples: true
      },
      {
        name: "designation",
        label: "Designation",
        placeholder: "select designation",
        allowMultiples: true
      },
      {
        name: "user_role",
        label: "User Role",
        placeholder: "select  user role",
        allowMultiples: true
      }
    ]
  };

  userAuditTrails = {
    filters: [
      {
        name: "description",
        label: "Description",
        placeholder: "select description",
        allowMultiples: true
      },
      {
        name: "paramId",
        label: "Record Id",
        placeholder: "select record id",
        allowMultiples: true
      },
      {
        name: "browserName",
        label: "Browser Name",
        placeholder: "select browserName",
        allowMultiples: true
      }
    ]
  };

  userRegistrations = {
    filters: [
      {
        name: "email_address",
        label: "Email Address",
        placeholder: "select email address",
        allowMultiples: true
      },
      {
        name: "first_name",
        label: "First Name",
        placeholder: "select first name",
        allowMultiples: true
      },
      {
        name: "last_name",
        label: "Last Name",
        placeholder: "select last name",
        allowMultiples: true
      },
      {
        name: "designation",
        label: "Designation",
        placeholder: "select designation",
        allowMultiples: true
      }
    ]
  };

  setupOptionsWithDefaultsIfAvailable(filters: FilterOption[], defaultFilterOptions?:FilterOption[]) {
    if (defaultFilterOptions) {

      return {
        filters: filters.map((option) => {
          const optionValueInfo = defaultFilterOptions.find((defaultOption) => defaultOption.name === option.name);
          if (optionValueInfo) {
            return { ...optionValueInfo, ...option };
          }
          return option;
        })
      };
    }
    return filters;
  }

  getDriverOptions(defaultFilterOptions?:FilterOption[]) {
    return this.setupOptionsWithDefaultsIfAvailable(this.driverOptions.filters, defaultFilterOptions);
  }

  getDeviceOptions(defaultFilterOptions?: FilterOption[]) {
    return this.setupOptionsWithDefaultsIfAvailable(this.deviceOptions.filters, defaultFilterOptions);
  }

  getDeviceEventOptions(defaultFilterOptions?:FilterOption[]) {
    return this.setupOptionsWithDefaultsIfAvailable(this.deviceEventOptions.filters, defaultFilterOptions);
  }

  getDeviceSmsAlertOptions(defaultFilterOptions?:FilterOption[]) {
    return this.setupOptionsWithDefaultsIfAvailable(this.deviceEventOptions.filters, defaultFilterOptions);
  }

  getSimCardOptions(defaultFilterOptions?:FilterOption[]) {
    return this.setupOptionsWithDefaultsIfAvailable(this.simCardOptions.filters, defaultFilterOptions);
  }

  getSimCardUsageLogsOptions(defaultFilterOptions?:FilterOption[]) {
    return this.setupOptionsWithDefaultsIfAvailable(this.simCardUsageLogsOptions.filters, defaultFilterOptions);
  }

  getVehicleOptions(defaultFilterOptions?:FilterOption[]) {
    return this.setupOptionsWithDefaultsIfAvailable(this.vehicleOptions.filters, defaultFilterOptions);
  }

  getVehicleBatteryInfoOptions(defaultFilterOptions?:FilterOption[]) {
    return this.setupOptionsWithDefaultsIfAvailable(this.vehicleBatteryInfoOptions.filters, defaultFilterOptions);
  }

  getCameraFeedsOptions(defaultFilterOptions?:FilterOption[]) {
    return this.setupOptionsWithDefaultsIfAvailable(this.cameraFeedsOptions.filters, defaultFilterOptions);
  }

  getServicesRepairsAndMaintenance(defaultFilterOptions?:FilterOption[]) {
    return this.setupOptionsWithDefaultsIfAvailable(this.servicesRepairAndMaintenanceOptions.filters, defaultFilterOptions);
  }

  getServicesTicketOverview(defaultFilterOptions?:FilterOption[]) {
    return this.setupOptionsWithDefaultsIfAvailable(this.servicesRepairAndMaintenanceOptions.filters, defaultFilterOptions);
  }
  getServicesAccidentManagement(defaultFilterOptions?:FilterOption[]) {
    return this.setupOptionsWithDefaultsIfAvailable(this.servicesRepairAndMaintenanceOptions.filters, defaultFilterOptions);
  }

  getServicesLicensingAndFines(defaultFilterOptions?:FilterOption[]) {
    return this.setupOptionsWithDefaultsIfAvailable(this.servicesRepairAndMaintenanceOptions.filters, defaultFilterOptions);
  }

  getServicesScheduledServices(defaultFilterOptions?:FilterOption[]) {
    return this.setupOptionsWithDefaultsIfAvailable(this.servicesRepairAndMaintenanceOptions.filters, defaultFilterOptions);
  }

  getServicesRoadsideAssistance(defaultFilterOptions?:FilterOption[]) {
    return this.setupOptionsWithDefaultsIfAvailable(this.servicesRepairAndMaintenanceOptions.filters, defaultFilterOptions);
  }
  getServicesVehicleInspection(defaultFilterOptions?:FilterOption[]) {
    return this.setupOptionsWithDefaultsIfAvailable(this.servicesRepairAndMaintenanceOptions.filters, defaultFilterOptions);
  }
  getUsers(defaultFilterOptions?:FilterOption[]) {
    return this.setupOptionsWithDefaultsIfAvailable(this.users.filters, defaultFilterOptions);
  }

  getUserAuditTrails(defaultFilterOptions?:FilterOption[]) {
    return this.setupOptionsWithDefaultsIfAvailable(this.userAuditTrails.filters, defaultFilterOptions);
  }

  getUserRegistrations(defaultFilterOptions?:FilterOption[]) {
    return this.setupOptionsWithDefaultsIfAvailable(this.userRegistrations.filters, defaultFilterOptions);
  }
}
