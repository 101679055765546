import React from "react";
import { NavLink } from "react-router-dom";
import { SidebarMenuIcon } from "./SidebarMenuIcon";

const MinimizedMultipleOptionsMenu = ({ pathName, menuName, menuIcon, isActive, subMenuOptions }) => {
  return (
    <div className="">
      <div className="flex justify-center w-full group">
        <div className={`p-1 rounded-md  ${isActive ? "bg-brand group-hover:sidebar-brand" : "group-hover:bg-sidebar-active"}`}>
          <SidebarMenuIcon isActive={isActive} menuIcon={menuIcon} />
        </div>

        <div className="absolute hidden border-l-[5px] border-l-background rounded-r-md left-10 z-60 group-hover:block bg-sidebar-active">
          <p className="pt-2 sidebar-link-name">{menuName}</p>
          <ul className={"p-4"}>
            {subMenuOptions?.map(({ url, name }) => (
              <li key={name + url} className="mb-1 last:mb-0">
                <NavLink
                  end
                  to={url}
                  className={({ isActive }) =>
                    "block border-l-2 border-white/20 hover:border-brand  pl-1 transition duration-150 truncate group-hover:text-sidebar-other " + (isActive ? "!text-brand" : "text-sidebar-link")
                  }
                >
                  <span className="text-sm font-medium duration-200 lg:sidebar-expanded:opacity-100 2xl:opacity-100 hover:text-brand">{name}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MinimizedMultipleOptionsMenu;
