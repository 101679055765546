import React, { Fragment } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Loading, OnError } from "..";
import Api from "../../api";
import Constant from "../../constant";
import { DistanceCoveredIcon, FuelConsumedIcon, IgnitionTimeIcon } from "../icons";
import GoogleMap from "../maps/google";

const VehicleLiveMovement = () => {
  const navigate = useNavigate();
  const { vehicleId } = useParams();
  const { data: vehicleRealtimeData = {}, isLoading, isError, error } = Api.vehicles.useGetRealtimeVehicleQuery(vehicleId, { pollingInterval: Constant.System.Vehicles.LiveMovement.Polling.Interval });

  //console.log({vehicleRealtimeData})
  if (isError) {
    navigate("/vehicles");
  }

  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (
    <Loading useLayout={true} isLoading={isLoading} loadingText="Loading Vehicle Realtime Information" />
  ) : (
    <DisplayVehicleRealtimeData vehicle={vehicleRealtimeData} />
  );
};

function DisplayVehicleRealtimeData({ vehicle }) {

  return (
    <Fragment>
      {/* Removed until future release
      <section className="section-container">
        <h2 className="font-semibold">
          Current Data: <span className="px-3 py-1 text-sm bg-brand text-other rounded-xl">{vehicle.reported?.isValid ? `Updated ${vehicle.reported.displayString}` : "No Data Available"}</span>
        </h2>

        <div className="grid grid-cols-1 mt-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <div className="speedometer-container">
            <h3>Vehicle Speed</h3>
            <ReactSpeedometer
              height={200}
              maxValue={240}
              value={vehicle.car_speed || vehicle.gps_speed || 0}
              needleColor="black"
              startColor="#22c55e"
              segments={10}
              endColor="#ef4444"
              textColor="#9ca3af"
            />
          </div>

          <div className="speedometer-container">
            <h3>Engine Load</h3>
            <ReactSpeedometer height={200} maxValue={100} value={vehicle.engine_load || 0} needleColor="black" startColor="#22c55e" segments={10} endColor="#ef4444" textColor="#9ca3af" />
          </div>

          <div className="speedometer-container">
            <h3>RPM (*1000)</h3>
            <ReactSpeedometer height={200} maxValue={8} value={vehicle.rpm || 0} needleColor="black" startColor="#22c55e" segments={10} endColor="#ef4444" textColor="#9ca3af" />
          </div>

          <div className="speedometer-container">
            <h3>Throttle Position</h3>
            <ReactSpeedometer height={200} maxValue={100} value={vehicle.throttle_position || 0} needleColor="black" startColor="#22c55e" segments={10} endColor="#ef4444" textColor="#9ca3af" />
          </div>
        </div>
      </section>
      */}
      <section className="section-container">
        <h2 className="font-semibold">Today</h2>
        <div className="flex flex-row gap-16">
          <div className="flex flex-row gap-2">
            <DistanceCoveredIcon classes="fill-primary h-8 w-8" />
            <p className="inline-flex flex-col">
              Distance Covered <span className="text-brand">----</span>
            </p>
          </div>
          <div className="flex flex-row gap-2">
            <FuelConsumedIcon classes="fill-primary h-8 w-8" />
            <p className="inline-flex flex-col">
              Fuel Consumed <span className="text-brand">----</span>
            </p>
          </div>
          <div className="flex flex-row gap-2">
            <IgnitionTimeIcon classes="fill-primary h-8 w-8" />
            <p className="inline-flex flex-col">
              Ignition On <span className="text-brand">{vehicle.ignition_on}</span>
            </p>
          </div>
        </div>
      </section>

      <section className="section-container h-88">
        <div className="inline-flex items-center gap-4">
          <h2 className="mb-2 text-xl font-semibold text-primary">Vehicle Live Locations</h2>
          <span className="inline-flex items-center gap-1 p-1 border rounded-full border-primary bg-other animate-pulse">
            <span className="w-3 h-3 rounded-full bg-primary"></span>
          </span>
        </div>
        <div className="w-full h-88">
          <GoogleMap vehicles={[vehicle]} />
        </div>
      </section>
    </Fragment>
  );
}
export default VehicleLiveMovement;
