import {z} from "zod";

export type SystemTheme = "light-theme" | "dark-theme";

export type BreadcrumbsItem = {
  name: string;
  path: string;
};

export const SystemThemeSetting = {
  DarkMode: "dark-theme",
  LIghtMode: "light-theme"
} as const;

export type ClientConfig = {
  system: {
    backEndUrl: string;
    apiRoot: string;
  };
  camerasInstalled: boolean;
  customer: {
    uuId: string;
    name: string;
    acronym: string;
    dummyEmail: string;
  };
  googleMap: {
    defaultCenter: string;
    apiKey: string;
  };
  logRocketAppCode: string | undefined;
  applicationInsightsConnectionString: string;
};

const DateTimeToHourDayCategory = z.object({
  dateTime: z.date().nullable(),
  hours: z.number().nullish(),
  days: z.number().nullish(),
  minutes: z.number().nullish(),
  seconds: z.number().nullish(),
  durationString: z.string().nullish(),
  category: z.string().nullish(),
  categoryInfo: z
    .object({
      order: z.union([z.number(), z.string()]),
      label: z.string(),
      colour: z.string(),
      name: z.string()
    })
    .optional()
});

export type DateTimeToHourDayCategoryType = z.infer<typeof DateTimeToHourDayCategory>;

export {DateTimeToHourDayCategory};
