import React, { useEffect,useState } from "react";
import DataCardSection, { ILabelValuePairProps } from "../../DataCardSection";

interface IDeviceDataCardSectionProps {
    isLoading: boolean;
    active: boolean;
    imei: string;
    hardwareVersion: string;
    firmwareName: string;
    serialNumber: string;
    isDeviceInstalled: boolean;
    enableDeviceLink?: boolean;
}

function DeviceDataCardSection({ isLoading, active, imei, hardwareVersion,firmwareName,serialNumber, enableDeviceLink = false, isDeviceInstalled }: IDeviceDataCardSectionProps) {
    const title = "Device Information";
    const [sectionData, setSectionData] = useState<ILabelValuePairProps[]>([]);
    
    useEffect(() => { 
        if (!isLoading) {
            const data: ILabelValuePairProps[] = [
                {
                    label: "Active",
                    value: active
                },
                {
                    label: "IMEI",
                    value: imei,
                    copyable: true
                },
                {
                    label: "Hardware Version",
                    value: hardwareVersion,
                },
                {
                    label: "Firmware Version",
                    value: firmwareName,
                    copyable: true
                },
                {
                    label: "Serial Number",
                    value: serialNumber,
                    copyable: true
                }
            
            ];
        
            if (enableDeviceLink) {
                const index = data.findIndex((data) => data.label === "IMEI");
                if (index !== -1) {
                    data[index] = {
                        ...data[index],
                        link: {
                            to: `/devices/${imei}`,
                            title: "View Device Details"
                        }
                    };
                }
             
            }
            setSectionData(data);
        }
    },
        [active, imei, hardwareVersion, firmwareName, serialNumber, enableDeviceLink, isLoading]);  

        const warningMessage = isDeviceInstalled ? "" : "No Device Installed";
  return (
    <div className="flex">
      <DataCardSection title={title} data={sectionData} warning={warningMessage} />
    </div>
  );
}

export default DeviceDataCardSection;
