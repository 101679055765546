import React from 'react'
import { IoMdPhonePortrait } from "react-icons/io";


function PhoneDeviceIcon() {
    return (
        <IoMdPhonePortrait />
    )
}

export default PhoneDeviceIcon