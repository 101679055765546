import {api} from "./api";

export const vehicleServicesApi = api.injectEndpoints({
  endpoints: builder => ({
    getAllVehicleServices: builder.query({
      query: () => "/vehicleServices",
      keepUnusedDataFor: 5
    }),
    getAllUpcomingVehicleServices: builder.query({
      query: () => "/vehicleServices?current=true",
      keepUnusedDataFor: 5
    }),
    getVehicleServices: builder.query({
      query: id => `/vehicleServices/${id}`
    })
  })
});

export const {useGetAllVehicleServicesQuery, useGetAllUpcomingVehicleServicesQuery, useGetVehicleServicesQuery} = vehicleServicesApi;
