import Constant from "../constant";
import {ClientConfig} from "../models/other.model";

const clientConfig: ClientConfig = {
  system: {
    backEndUrl: "https://rikatec-riot-api.azurewebsites.net",
    apiRoot: "/api/v1.0"
  },
  camerasInstalled: true,
  customer: {
    uuId: "18C83AC1-243D-4956-B3FA-85DB924CCAC3",
    name: "Bushbuckridge",
    acronym: "BLM",
    dummyEmail: "user@bushbuckridge.gov.za"
  },
  googleMap: {
    defaultCenter: "-24.84044,31.07266",
    apiKey: Constant.System.Google.MapApiKey
  },
  logRocketAppCode: Constant.System.LogRocket.AppCode,
  applicationInsightsConnectionString: "InstrumentationKey=f574029a-4600-4a22-bf1a-7b17797e6723;IngestionEndpoint=https://southafricanorth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://southafricanorth.livediagnostics.monitor.azure.com/"
};

export default clientConfig;
