import React, { Fragment } from 'react'
import { Link } from "react-router-dom";
import Api from "../../../api";
import { LocationIcon, TriangleExclamationIcon } from "../../icons";
import { MdOutlineSpeed } from "react-icons/md";
import { defaultUserAccess } from "../../../core/userAccess";
import { useSelector, TypedUseSelectorHook } from "react-redux";
import { RootState } from "../../../store/store";

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

type Props = {
  deviceId: string;
  registration: string;
  vin: string;
  makeAndModel: string;
  speed: number;
  reported: number;
  vehicleId: string;
}

function VehicleInfo({ deviceId, registration,vin, makeAndModel, speed, reported, vehicleId }: Props) {
  const user = useTypedSelector((state) => state.auth.user);
  const { data: vehicleLocationInfo, isLoading, isError } = Api.vehicles.useGetVehicleLocationQuery(vehicleId);
  const isVehicleLocationLoaded = !isLoading && !isError;
  const vehicleIdentifier = registration ? { label: "Registration Number", value: registration } : { label: "VIN", value: vin };
  const userCanViewDevice = user.isSignedIn &&  user.access.devices.view ;
  
  console.log({user})

  return (
    <div className='!w-[300px] h-full  flex flex-col justify-center items-center relative '>
          <div className="p-4 rounded-md bg-other ">
          {userCanViewDevice ? <DisplayVehicleDevice deviceId={deviceId} /> : null}
          <div className="grid grid-cols-2 text-center">
              <div className="w-full p-2 border map-popup">{vehicleIdentifier.label}</div>
              <Link to={`/vehicles/${vehicleId}`}>
            <div className="w-full p-2 border ">{vehicleIdentifier.value}</div>
              </Link>
          </div>
          <div className="grid grid-cols-2 text-center">
            <div className="w-full p-2 rounded-bl-[8px] map-popup border">Vehicle Model</div>
            <div className="w-full p-2 rounded-br-[8px] border">{makeAndModel}</div>
        </div>
        <div className="flex flex-col gap-2 mt-2">
      { isVehicleLocationLoaded ? (
        <div className="w-full rounded-[8px] text-center flex justify-center space-x-2 items-center border p-2 border-tertiary text-secondary">
          <LocationIcon  />
              <span>{vehicleLocationInfo.locationName}</span>
        </div>
      ) : null }
    </div>    
          <div className="flex flex-col gap-2 mt-2">
            {reported > 24 ? (
              <div className="w-full rounded-[8px] text-center flex justify-center space-x-2 items-center border p-2 border-tertiary bg-warn text-secondary">
                <TriangleExclamationIcon />
                <span>No updated reports from vehicle</span>
              </div>
            ):
              <>
                {speed > 0 && (
                  <div className="w-full rounded-[8px] text-center flex  justify-center space-x-2 items-center border p-1 border-tertiary text-primary">
                    <MdOutlineSpeed className="w-8 h-8 text-current map-popup-text" />
                    <span className="font-bold">{speed} kph</span>
                  </div>
                )}
              </>
      }
          </div>
          
      </div>


          <svg xmlns="http://www.w3.org/2000/svg" className='-mt-2 rotate-90 fill-other stroke-other' width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><polygon points="6 3 20 12 6 21 6 3"/></svg>
              
   

      
    </div>
  )
}

type DisplayVehicleDeviceProps = {
  deviceId: string;
}

function DisplayVehicleDevice({ deviceId  }: DisplayVehicleDeviceProps) {
  return (
    
      <div className="grid w-full grid-cols-2 text-center">
      <div className="w-full p-2 rounded-tl-[8px] map-popup border">Device Number</div>
        <Link to={`/devices/${deviceId}`}>
          <div className="w-full p-2 rounded-tr-[8px]  border">{deviceId}</div>
        </Link>
      </div>
    
  )
}

export default VehicleInfo