import blmLogo from "../images/blm-logo.png";
import blmLogoW from "../images/blm-logo-W.png";
import gtlmLogo from "../images/gtlm-logo.png";
import gtlmLogoW from "../images/gtlm-logo-W.png";
import ndmLogo from "../images/ndm-logo.png";
import ndmLogoW from "../images/ndm-logo-W.png";
import plkmLogo from "../images/plkm-logo.png";
import plkmLogoW from "../images/plkm-logo-W.png";
import riiotLogo from "../images/riiot-logo.png";

import thlmLogo from "../images/thlm-logo.png";
import thlmLogoW from "../images/thlm-logo-W.png";
import mdmLogo from "../images/mdm-logo.png";
import mdmLogoW from "../images/mdm-logo-W.png";
import { SystemTheme } from "../models/other.model";


export default function getEntityLogo(entityAcronym: string, theme: SystemTheme) {
  console.log({entityAcronym, theme});
  if (entityAcronym === "BLM") {
    
    return theme === "light-theme"  ? blmLogoW  : blmLogo;
  }

  if (entityAcronym === "GTLM") {
    return gtlmLogo;
  }

  if (entityAcronym === "NDM") {
    return ndmLogo;
  }

  if (entityAcronym === "THLM") {
    return thlmLogo;
  }

  if (entityAcronym === "PLKM") {
    return plkmLogo;
  }

  if (entityAcronym === "MDM") {
    return mdmLogo;
  }
  
  return riiotLogo;
};


