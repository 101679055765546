import React,{Fragment} from "react";

import { AlertIcon } from "../icons/SidebarIcons";
import Timeline from "../Timeline";
import { dateTimeDisplay } from "../../core/utilities";
import DeviceDataCardSection from "../device/dataCardSection/DeviceDataCardSection";
import SimCardDataCardSection from "../simCard/dataCardSection/SimCardDataCardSection";

function VehicleSmsTracking({vehicle,vehicleSmsTracking, hasAccessToDevices }) {

    const { smsTrackingInfo, device } = vehicleSmsTracking;

    const vehicleSmsTrackingTimeLine = () => {
        return smsTrackingInfo.map((smsTracking) => {
    
          return {
            date: dateTimeDisplay(smsTracking.eventDate),
            title: `${smsTracking.description}`,
            subTitle: `Initiated at ${dateTimeDisplay(smsTracking.created_at,true)}${smsTracking.isDelivered ? `${smsTracking.isResponseReceived? "," : " and"} delivered at ${dateTimeDisplay(smsTracking.send_sms_delivered_at,true)}` : ""}${smsTracking.isResponseReceived ? ` and received response at ${dateTimeDisplay(smsTracking.completed_at,true)}` : ""}.`,
            
          }
          
      });
  }
  console.log({smsTrackingInfo})
  return (
      <section className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2">
                  { hasAccessToDevices && device ? (
          <Fragment>
            <DeviceDataCardSection isLoading={false} active={device.active} imei={vehicle.device_id} hardwareVersion={device.device_type} firmwareName={device.firmware_name} serialNumber={device?.serial_number} enableDeviceLink={true} isDeviceInstalled={true} />
            <SimCardDataCardSection active={device.primary_sim_card_active} apn={device.primary_sim_cards_apn} iccid={device.primary_sim_iccid} msisdn={device.primary_sim_msisdn} id={device.primary_sim_card_external_id} isSimCardInstalled={device.primary_sim_iccid ? true : false} enableSimLink={true} />
          </Fragment>
        ) : null}
          <div className="section-container lg:col-span-3" tabIndex="4">
        <h2 className="font-semibold">Sms Tracking</h2>
        <Timeline items={vehicleSmsTrackingTimeLine()}/>
       
        {smsTrackingInfo.length === 0 ? (
          <p className="inline-flex gap-2">
            {" "}
            <AlertIcon classes={"fill-current"} /> No Sms Tracking Found !
          </p>
        ) : null}
      </div>
          </section>
  )
}

export default VehicleSmsTracking