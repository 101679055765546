interface IInfoIconProps {
  color: string
}

const InfoIcon = ({ color }: IInfoIconProps) => {
    return ( 
      <svg aria-hidden="true" className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200" stroke="currentColor" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={{ fill: color }}>
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"></path>
    </svg>
    );
}
 
export default InfoIcon;