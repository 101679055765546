import systemConfig from "../../config/systemConfiguration";

const {baseUrl, apiRoot} = systemConfig.backend;

export const baseRequestConfig = {
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
};

const apiRequestConfig = {
  baseURL: `${baseUrl}${apiRoot}`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
};

export default apiRequestConfig;
