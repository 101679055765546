interface IAlertIconProps {
  color?: string
}

const AlertIcon = ({ color="var(--color-primary)" }: IAlertIconProps) => {
    return ( 
<svg xmlns="http://www.w3.org/2000/svg" className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200" height="16" width="2" viewBox="0 0 64 512" style={{ fill: color }}>
  <path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V320c0 17.7 14.3 32 32 32s32-14.3 32-32V64zM32 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/>
</svg>
    );
}
 
export default AlertIcon;