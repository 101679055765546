import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useLazyGetMicrosoftLoginUrlQuery, useLoginMutation } from "../api/auth/authApi";
import { getLocationName } from "../api/googleApi";
import { useUserPasswordResetRequestMutation } from "../api/rtk/baseApi";
import { AlertDialog, ConfirmationDialog, InfoDialog } from "../components";
import Loading from "../components/Loading";
import ToggleButton from "../components/toggle/ToggleButton";
import systemConfig from "../config/systemConfiguration";
import getEntityLogo from "../core/entityLogo";
import { getBrowserInfoByUserAgent } from "../core/jsUtilitiesFuzzyLogic";
import { setupUserAccess } from "../core/userAccess";
import { clearSignInFail, setCredentials, setSignInFail } from "../features/auth/authSlice";
import { RootState } from "../store/store";
import { defaultBrowserTheme } from "../core/utilities";
import { selectCurrentTheme } from "../features/settings/settingsSlice";

interface IBrowserLocation {
  geoLocationFailed?: boolean;
  geoLocationNotSupported?: boolean;
  latitude?: number;
  longitude?: number;
  name?: string;
}

export default function Login() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>("");
  const [login] = useLoginMutation();
  const navigate = useNavigate();

  const location = useLocation();

  const targetUrl = location?.state?.from?.pathname || "/";

  // function to auth user
  const handleSubmit = async (emailAddress: string, password: string) => {
    setIsLoading(true);
    return new Promise((resolve) => {
      try {
        let loginErrorMessage = "";
        const { entity } = systemConfig;

        login({ emailAddress, password, entity: { uuId: entity.uuId } })
          .unwrap()
          .then(async (userData) => {
            // console.log({ userData });
            const { accessToken, id, designation, isRiiotAdmin, isRiiotUser, fullName, landingPage, role, access, isRemoteLogEnabled, fleetInfo, refreshTokenExpiry, tokenExpiry } = userData;

            //console.log({ userData });

            const userCredentials = {
              accessToken,
              id,
              emailAddress: emailAddress,
              designation,
              fullName,
              isRiiotAdmin,
              isRiiotUser,
              role,
              access,
              entity,
              isRemoteLogEnabled,
              fleetInfo,
              landingPage,
              refreshTokenExpiry,
              tokenExpiry
            };

            userCredentials.access = await setupUserAccess(role);
            // console.log({ userCredentials });

            dispatch(setCredentials(userCredentials));
            resolve(null);
            // window.location.href = location?.state?.from?.pathname || "./"
            navigate(landingPage || targetUrl);
          })
          .catch((error) => {
            if (error.status === 401 && error.data.code === 401.03) {
              loginErrorMessage = "Login failed, your credentials are invalid.";
              dispatch(setSignInFail({ emailAddress }));
            } else if (error.status === 503) {
              loginErrorMessage = "Login failed, either your email address or password is invalid.";
            } else {
              console.error(error);
            }

            setLoginErrorMessage(loginErrorMessage);
            setIsLoading(false);
            resolve(loginErrorMessage);
          });
      } catch (error: any) {
        const errorMessage = `Login Failed. ${error?.data?.error?.message || "No valid response from the server, please try again"}`;
        console.error(error);
        setIsLoading(false);
        setLoginErrorMessage(errorMessage);
        resolve(errorMessage);
      }
    });
  };

  //console.log({ isLoading  });
  return isLoading ? <Loading isLoading={isLoading} loadingText="Signing in" isLogin={true} /> : <DisplayLoginPage doLogin={handleSubmit} loginErrorMessage={loginErrorMessage} />;
}

interface IDisplayLoginPageProps {
  doLogin: (emailAddress: string, password: string) => void;
  loginErrorMessage: string | undefined;
}

function DisplayLoginPage({ doLogin, loginErrorMessage }: IDisplayLoginPageProps) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const defaultEmailAddress = searchParams.get("emailAddress") || undefined;
  const microsoftErrorMessage = searchParams.get("error") || "";
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [secondPage, setSecondPage] = useState(false);
  const [isValidEmail, setEmailValid] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [userLocation, setUserLocation] = useState<IBrowserLocation>({});
  const [commandConfirmation, setCommandConfirmation] = useState<{ confirm: boolean; message?: string }>({ confirm: false });
  const [alertInfo, setAlertInfo] = useState<{ show: boolean; message?: string }>({ show: false });
  const [info, setInfo] = useState<{ show: boolean; message?: string | JSX.Element; onClick?: () => void }>({ show: false });
  const [resetUserPassword] = useUserPasswordResetRequestMutation();
  const [theme] = useState(useSelector(selectCurrentTheme) || defaultBrowserTheme());
  const signInState = useSelector((state: RootState) => state.auth.signingInAttempts);

  const showResetPassword = signInState && signInState.emailAddress === emailAddress ? signInState.failedSignInCount > 1 : false;

  const userRef = useRef<HTMLInputElement | null>(null);
  const passRef = useRef<HTMLInputElement | null>(null);

  const validateEmail = (email: string) => {
    let validatedEmail = /\S+@\S+\.\S+/;
    return validatedEmail.test(email);
  };

  useEffect(() => {
    const savedEmail = localStorage.getItem("savedEmail");
    if (savedEmail) {
      setEmailAddress(savedEmail);
      setIsChecked(true);
      if (validateEmail(savedEmail)) {
        setSecondPage(true);
      }
    }
  }, []);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      localStorage.setItem("savedEmail", emailAddress);
    } else {
      localStorage.removeItem("savedEmail");
    }
  };

  useEffect(() => {
    if (showResetPassword) {
      // Get user location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const location: IBrowserLocation = { latitude: position.coords.latitude, longitude: position.coords.longitude };
            getLocationName(location).then((locationNameResponse) => {
              const locationName = locationNameResponse[0].formatted_address;
              location.name = locationName;
              setUserLocation(location);
            });
          },
          (error) => {
            console.error("Error getting location:", error.message);
            setUserLocation({ geoLocationFailed: true });
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        setUserLocation({ geoLocationNotSupported: true });
      }
    }
  }, [showResetPassword]);

  useEffect(() => {
    if (!secondPage && userRef && userRef.current !== null) {
      userRef.current.focus();
    }

    return () => {
      userRef.current = null;
    };
  }, [secondPage]);

  useEffect(() => {
    setErrorMsg("");
  }, [emailAddress, password]);
  useEffect(() => {
    setEmailAddress("");
    setSecondPage(false);
    if (microsoftErrorMessage === "ms-failed") {
      setErrorMsg("Microsoft login failed, please try again");
    }
    if (microsoftErrorMessage === "ms-invalid") {
      setErrorMsg("Microsoft login failed, Invalid user");
    }
  }, [microsoftErrorMessage]);

  useEffect(() => {
    if (isValidEmail && secondPage && passRef && passRef.current) {
      passRef.current.focus();
    }

    return () => {
      passRef.current = null;
    };
  }, [isValidEmail, secondPage]);

  useEffect(() => {
    if (emailAddress) {
      let emailValidity = validateEmail(emailAddress);
      setEmailValid(emailValidity);

      if (emailValidity) {
        setErrorMsg("");
      }
    }
  }, [emailAddress]);

  const gotoSecondPage = () => {
    if (isValidEmail) {
      setSecondPage(true);
    } else {
      setErrorMsg("Your Email is not valid");
    }
  };

  const onResetConfirm = () => {
    setInfo({ show: false });
    dispatch(clearSignInFail());
  };

  const handlePasswordReset = async () => {
    const message = "Are you sure you want to submit a Password Reset Request?";
    setCommandConfirmation({ confirm: true, message });
  };
  const gotoResetPassword = async () => {
    const { entity } = systemConfig;
    const { browserName, linkInfo } = await getBrowserInfoByUserAgent();
    console.log({ browserName, userLocation });
    const { geoLocationFailed, geoLocationNotSupported, latitude, longitude, name: locationName } = userLocation;

    if (geoLocationFailed || geoLocationNotSupported) {
      //Display Error Message
      if (geoLocationFailed) {
        setInfo({
          show: true,
          message: (
            <div>
              <p>Location needs to be turned on to reset password.</p>
              <br></br>
              <p>Follow the link below and follow instructions to turn on location for {browserName}.</p>
              <br></br>
              <br></br>
              <p>
                Click{" "}
                <a href={linkInfo} target="_blank" style={{ color: "var(--color-brand)" }} rel="noreferrer">
                  here
                </a>{" "}
                for instructions.
              </p>
              <br></br>
            </div>
          ),
          onClick: onResetConfirm
        });
      } else {
        setInfo({ show: true, message: "Location Not Supported. Try Again Later Or Contact Rikatec for support", onClick: onResetConfirm });
      }
    } else {
      // Display busy loading message
      const geoLocation = { latitude, longitude };
      const resetPasswordResponse = await resetUserPassword({ emailAddress, entity: { uuId: entity.uuId }, browserName, geoLocation, locationName }).unwrap();
      console.log({ resetPasswordResponse });

      // once the reset is successful
      if (resetPasswordResponse.emailSendResponse.response.includes("250") && resetPasswordResponse.emailSendResponse.response.includes("OK")) {
        setInfo({ show: true, message: "Reset password email has been sent. Please check your email.", onClick: onResetConfirm });
      } else {
        setInfo({ show: true, message: "Password Reset Request Can Not Be Fulfilled. Try Again Later Or Contact Rikatec", onClick: onResetConfirm });
      }
    }
  };

  const handleNegativeConfirmation = () => {
    setCommandConfirmation({ confirm: false });
  };

  const handlePositiveConfirmation = async () => {
    setCommandConfirmation({ confirm: false });
    gotoResetPassword();
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setEmailAddress("");
    setPassword("");
    setSecondPage(false);

    try {
      await doLogin(emailAddress, password); //.then((payload) => {
    } catch (err) {
      console.error(err);
    }
  };

  const showSecondPage = isValidEmail && secondPage;
  const [isChecked, setIsChecked] = useState(false);

  // console.log({ secondPage, isValidEmail, showResetPassword });

  return (
    <section className="flex items-center justify-center w-screen h-screen overflow-hidden bg-background">
      <ConfirmationDialog show={commandConfirmation.confirm} onNo={handleNegativeConfirmation} onYes={handlePositiveConfirmation} confirmationMessage={commandConfirmation.message} />
      <InfoDialog onClick={info.onClick} show={info.show} infoMessage={info.message} />
      <AlertDialog show={alertInfo.show} alertMessage={alertInfo.message} onClick={() => setAlertInfo({ show: false })} />
      <form onSubmit={onSubmit} className="shadow-xl section-container" autoComplete="off">
        <img src={getEntityLogo(systemConfig.entity.acronym,theme)} alt={"logo"} height={150} width={150} className="mx-auto" />

        <h1 className="font-bold text-center">Sign In</h1>
        <div>
          {loginErrorMessage ? (
            <div className="flex items-center my-5">
              <p className="mt-2 text-sm text-center text-red-500">{loginErrorMessage}</p>
            </div>
          ) : null}
          <label htmlFor="email" className="block mb-2 text-sm font-medium text-primary">
            Email
          </label>
          <input
            name="email"
            ref={userRef}
            onChange={(e) => setEmailAddress(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && validateEmail(emailAddress) && setSecondPage(true)}
            type="email"
            id="email"
            value={emailAddress}
            className="input-field"
            placeholder={systemConfig.entity.dummyEmail}
            title="Please provide a valid email address"
            disabled={showSecondPage}
            defaultValue={defaultEmailAddress}
            autoComplete="username"
          />
          <span title="Error Message" className="mt-2 text-sm text-red-500">
            {errorMsg}
          </span>
        </div>

        {showSecondPage ? (
          <>
            <div className="relative">
              <label htmlFor="password" className="block mb-2 text-sm font-medium text-primary">
                Password
              </label>

              <div className="absolute right-2 bottom-3" onClick={() => setShowPassword((n) => !n)} data-testid="show-password">
                {showPassword ? (
                  <svg className="fill-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                  </svg>
                ) : (
                  <svg className="fill-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M17.882 19.297A10.949 10.949 0 0 1 12 21c-5.392 0-9.878-3.88-10.819-9a10.982 10.982 0 0 1 3.34-6.066L1.392 2.808l1.415-1.415 19.799 19.8-1.415 1.414-3.31-3.31zM5.935 7.35A8.965 8.965 0 0 0 3.223 12a9.005 9.005 0 0 0 13.201 5.838l-2.028-2.028A4.5 4.5 0 0 1 8.19 9.604L5.935 7.35zm6.979 6.978l-3.242-3.242a2.5 2.5 0 0 0 3.241 3.241zm7.893 2.264l-1.431-1.43A8.935 8.935 0 0 0 20.777 12 9.005 9.005 0 0 0 9.552 5.338L7.974 3.76C9.221 3.27 10.58 3 12 3c5.392 0 9.878 3.88 10.819 9a10.947 10.947 0 0 1-2.012 4.592zm-9.084-9.084a4.5 4.5 0 0 1 4.769 4.769l-4.77-4.769z" />
                  </svg>
                )}
              </div>

              <input
                name="password"
                ref={passRef}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? "text" : "password"}
                id="password"
                className="input-field"
                required
                autoComplete="current-password"
              />
            </div>

            <div className="flex items-center gap-2">
              <div>
                <h1>Remember me</h1>
              </div>
              <ToggleButton isChecked={isChecked} onToggle={handleToggle} />
            </div>
          </>
        ) : null}

        <div className="flex flex-col gap-4 mt-4">
          {!secondPage ? (
            <Fragment>
              <button name="continue" type="button" className="w-full btn-primary" onClick={gotoSecondPage} disabled={!emailAddress || emailAddress.length === 0}>
                Continue
              </button>
              <AdditionalAuthMethods setErrorMsg={setErrorMsg} />
              <DisplayLoginFooter showResetPassword={showResetPassword} onPasswordReset={handlePasswordReset} emailAddress={emailAddress} />
            </Fragment>
          ) : isValidEmail && secondPage ? (
            <Fragment>
              <button name="sign-in" type="submit" className="w-full btn-primary">
                Sign In
              </button>
              <AdditionalAuthMethods setErrorMsg={setErrorMsg} />
              <DisplayLoginFooter showResetPassword={showResetPassword} onPasswordReset={handlePasswordReset} emailAddress={emailAddress} />
            </Fragment>
          ) : null}
        </div>
      </form>
    </section>
  );
}

type DisplayLoginFooterProps = {
  showResetPassword: boolean;
  onPasswordReset: () => void;
  emailAddress: string;
};

function DisplayLoginFooter({ showResetPassword, onPasswordReset, emailAddress }: DisplayLoginFooterProps) {
  return showResetPassword ? (
    <p className="text-sm">
      Forgot Password? Click{" "}
      <span
        className={"text-brand"}
        onClick={onPasswordReset}
        // disabled={!emailAddress || emailAddress.length === 0}
        style={{ cursor: "pointer" }}
      >
        here{" "}
      </span>{" "}
      to reset password{" "}
    </p>
  ) : (
    <p className="text-sm">
      Need some support? Contact us at <span className="text-brand">+27 10 822 2240</span>{" "}
    </p>
  );
}

function AdditionalAuthMethods({ setErrorMsg }: { setErrorMsg: (message: string) => void }) {
  const [triggerGetMicrosoftLoginUrl] = useLazyGetMicrosoftLoginUrlQuery();
  const handleLoginWithMicrosoft = async () => {
    // console.log(window.origin)
    try {
      const { url } = await triggerGetMicrosoftLoginUrl(`${window.origin}/microsoft/callback`).unwrap();
      if (url) {
        window.location.href = url;
      } else {
        setErrorMsg("Failed to authenticate with Microsoft, please try again");
        console.error("Microsoft login URL not available");
      }
    } catch (error) {
      setErrorMsg("Failed to fetch Microsoft login URL");
      console.error("Failed to fetch Microsoft login URL", error);
    }
  };

  return (
    <div>
      <div className="flex items-center py-3 text-xs text-gray-400 uppercase before:flex-1 before:border-t before:border-gray-200 before:me-6 after:flex-1 after:border-t after:ms-6">Or</div>
      <button
        type="button"
        onClick={handleLoginWithMicrosoft}
        className="inline-flex items-center justify-center w-full px-4 py-3 text-sm font-medium border border-gray-200 rounded-lg shadow-sm text-primary bg-quaternary gap-x-2 hover:bg-gray-50 focus:outline-none focus:bg-gray-50"
      >
        <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid">
          <path fill="#F1511B" d="M121.666 121.666H0V0h121.666z" />
          <path fill="#80CC28" d="M256 121.666H134.335V0H256z" />
          <path fill="#00ADEF" d="M121.663 256.002H0V134.336h121.663z" />
          <path fill="#FBBC09" d="M256 256.002H134.335V134.336H256z" />
        </svg>
        Sign in with Microsoft
      </button>
    </div>
  );
}
