import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Layout, OnError, Tabs } from "../../components";
import { useSelector } from "react-redux";
import { getNameFromUrlPath } from "../../core/utilities";
import DeviceDetailsSkeleton from "../../skeletons/DeviceDetailsSkeleton";
import UserRoleAccess from "../../constant/userRoleAccess";
import useHasAccessToFeature from "../../hooks/useHasAccessToFeature";
import Api from "../../api";
import Breadcrumb from "../../components/Breadcrumb";
import { TabIconTypes } from "../../components/Tabs";
import systemConfig from "../../config/systemConfiguration";
import {DeviceOverview, DeviceCameraFootagesView, DeviceEventsView, DeviceSmsAlertsView, DeviceSmsOutView, DeviceTripPointsView } from "./views";
import ProcessFlowDialog, { UserProcessFlowInit } from "../../components/flow/ProcessFlowDialog";
import { ProcessFlowStage } from "../../constant";
import { selectPreviousPage } from "../../features/settings/settingsSlice";

export const DeviceTabs = Object.freeze({
  Overview: "Overview",
  Events: "Device Events",
  SmsAlerts: "Sms Alerts",
  SmsSentOut: "Sms Sent Out",
  CameraFootage: "Camera Footage",
  TripPoints: "Trip Points"
});

function DeviceDetails() {
  const { deviceId } = useParams();
    const { data: device, isLoading, isError, error } = Api.devices.useGetDeviceQuery(deviceId);
  const navigationPath = useSelector(selectPreviousPage);
  

  const fromLocation = {
    path: navigationPath.previous || "/devices",
    name: getNameFromUrlPath(navigationPath.previous) || "Devices"
  };
  //console.log({device});

  return isError ? <OnError error={error} /> : isLoading ? <DeviceDetailsSkeleton isLoading={isLoading} /> : <DisplayDeviceDetails device={device} fromLocation={fromLocation} />;
}

function DisplayDeviceDetails({ device, fromLocation }) {
  const isSimCardInstalled = device.primary_sim_iccid && device.primary_sim_iccid.length > 0;
  const [processVehicleUnlink, setProcessVehicleUnlink] = useState(UserProcessFlowInit);
  const camerasInstalled = systemConfig.features.camerasInstalled;
  const { data: deviceEvents = [] } = isSimCardInstalled ? Api.devices.useGetDeviceEventsQuery(device.id) : { data: [] };
  const { data: deviceSmsOut = [] } = isSimCardInstalled ?  Api.simCards.useGetSimCardAllSmsSentOutQuery(device.primary_sim_iccid) : { data: [] };
  const { data: deviceSmsAlerts = [] } = Api.devices.useGetAllDevicesSmsAlertsQuery(device.id);
  const { data: deviceCameraFeeds = [] } = camerasInstalled ? Api.devices.useGetDeviceCameraFeedsQuery(device.id) : {data:[]};
  const { data: deviceTripPoints = [] } = Api.devices.useGetDeviceTripPointsQuery(device.id);
  const { hasAccess: hasAccessToUnlinkVehicle } = useHasAccessToFeature(UserRoleAccess.vehicleUnlinkDevice);
  const [currentTab, setCurrentTab] = useState(DeviceTabs.Overview);
  const deviceId = device.id;
  
  const eventsAvailable = deviceEvents && deviceEvents.length > 0;
  const smsOutAvailable = deviceSmsOut && deviceSmsOut.length > 0;
  const smsAlertsAvailable = deviceSmsAlerts && deviceSmsAlerts.length > 0;
  const cameraFootageAvailable = deviceCameraFeeds && deviceCameraFeeds.length > 0;
  const tripPointsAvailable = deviceTripPoints && deviceTripPoints.length > 0 ;

 
  const breadcrumbItems = () => {
    const items = [];
    if (fromLocation && fromLocation.name) {
      items.push({ name: fromLocation.name, path: fromLocation.path });
    } else {
      items.push({ name: "Devices", path: "/devices" });
    }
    items.push({ name: `Device ${deviceId}`, path: `/devices/${deviceId}` });

    return items;
  };

  const onTabItemClick = (e) => {
    const tab = e.target.id;

    console.log(tab, e);
    setCurrentTab(tab);
  };

  const tabItems = () => {
    const onClick = onTabItemClick;
    

    const items = [
      { name: DeviceTabs.Overview, icon: TabIconTypes.Home, onClick, currentTab },
      { name: DeviceTabs.Events, icon: TabIconTypes.Alert, onClick, currentTab, enabled: eventsAvailable },
      { name: DeviceTabs.SmsAlerts, icon: TabIconTypes.Sms, onClick, currentTab, enabled: smsAlertsAvailable },
      { name: DeviceTabs.SmsSentOut, icon: TabIconTypes.Sms, onClick, currentTab, enabled: smsOutAvailable },
      { name: DeviceTabs.TripPoints, icon: TabIconTypes.LocationDot, onClick, currentTab, enabled: tripPointsAvailable },

    ];

    if (camerasInstalled) {
      items.push({ name: DeviceTabs.CameraFootage, icon: TabIconTypes.CameraFootage, onClick, currentTab, enabled: cameraFootageAvailable });
    }

    return items;
  };
  
  const handleDeviceUnlink = () => {
    const confirmMessage = `Are you sure you want to unlink vehicle from this device?`;
    setProcessVehicleUnlink({ stage: ProcessFlowStage.Confirm, confirmMessage });

  }
  const onConfirmVehicleUnlink = ({confirm,stage}) => {
    console.log({ confirm });
    setProcessVehicleUnlink({ stage });
    
    if (confirm.yes) {
      const processingMessage = "Removing link between Vehicle and Device";
      setProcessVehicleUnlink({ stage: ProcessFlowStage.Processing, processingMessage });
    }

  }
  //console.log({camerasInstalled, device, deviceCameraFeeds, deviceTripPoints });
  
  return (
    <Layout>
      <header className="flex flex-row justify-between p-2 rounded-sm shadow-lg bg-other col-span-full xl:col-span">
        <Breadcrumb items={breadcrumbItems()} />
        <div className="flex flex-row items-center gap-2 px-5 py-4 font-semibold text-primary"></div>
        {/* buttons */}
        <div className="flex flex-col gap-4 md:items-center md:flex-row"></div>
      </header>
      <ProcessFlowDialog
        processStage={processVehicleUnlink.stage}
        confirmMessage={processVehicleUnlink.confirmMessage}
        onConfirm={onConfirmVehicleUnlink} />
      <Tabs items={tabItems()} />
      {currentTab === DeviceTabs.CameraFootage ? (
        <DeviceCameraFootagesView cameraFeeds={deviceCameraFeeds} />
      ) : currentTab === DeviceTabs.Events ? (
        <DeviceEventsView deviceEvents={deviceEvents} />
      ) : currentTab === DeviceTabs.Overview ? (
        <DeviceOverview device={device} onDeviceUnlink={handleDeviceUnlink} hasAccessToUnlinkVehicle={hasAccessToUnlinkVehicle} />
      ) : currentTab === DeviceTabs.SmsAlerts ? (
        <DeviceSmsAlertsView deviceSmsAlerts={deviceSmsAlerts} />
      ) : currentTab === DeviceTabs.SmsSentOut ? (
        <DeviceSmsOutView deviceSmsOut={deviceSmsOut} />
      ) : currentTab === DeviceTabs.TripPoints ? (<DeviceTripPointsView tripPoints={deviceTripPoints} />)
       : null}
    </Layout>
  );
}

export default DeviceDetails;
