import React from "react";
import { useEffect, useState } from "react";
import { lastArrayElement, nearestInclusiveMaxArray } from "../../core/utilities";
import TableColumnSelectableOptions from "../table/TableColumnSelectableOptions";

type PageFilterPropsForColumnOptions = {
    showTableColumnSelect?: boolean,
    tableColumnSelectOptions: any,
    onColumnOptionsChange: () => void
}

type PageFilterPropsForNoneColumnOptions = {
    showTableColumnSelect?: undefined,
    tableColumnSelectOptions?: undefined
    onColumnOptionsChange?: undefined
}

type PageFilterProps = {
    itemsPerPage: number,
    setItemsPerPage: (option: any) => void,
    totalNumberOfItems: number,
    isFiltered: boolean,
    numberOfItemsOnCurrentPage: number,
    recordsName: string

} & (PageFilterPropsForColumnOptions | PageFilterPropsForNoneColumnOptions)

const PageFilter = ({ itemsPerPage, setItemsPerPage, totalNumberOfItems, isFiltered, numberOfItemsOnCurrentPage, recordsName, showTableColumnSelect, tableColumnSelectOptions, onColumnOptionsChange }: PageFilterProps) => {


    const [pageFilterOptions, setPageFilterOptions] = useState([10])
    useEffect(() => {

        const filterOptions = [10, 25, 75, 100, 150, 200, 250, 500, 750, 1000, 10000, 25000, 50000];
        const validOptions = nearestInclusiveMaxArray(filterOptions, totalNumberOfItems) as number[];
        setPageFilterOptions(validOptions);
    }, [totalNumberOfItems]);

    const perPageMessage = `${recordsName ? recordsName : "Records"} Per Page`;
    const selectedPageOption = pageFilterOptions && pageFilterOptions.includes(itemsPerPage) ? itemsPerPage : lastArrayElement(pageFilterOptions);
    const showingMessage = `Showing ${isFiltered ? "Filtered results" : ""} ${itemsPerPage > totalNumberOfItems ? totalNumberOfItems : numberOfItemsOnCurrentPage || itemsPerPage} of ${totalNumberOfItems}`;
    return (totalNumberOfItems > 10 ?
        <div className="filter-card">

            <div className="flex justify-between">
                <div className="flex flex-row justify-between ">
                    <nav className="flex flex-row items-center gap-2 text-primary" style={{ zIndex: 0 }}>
                        <span className="px-2">{perPageMessage}:</span>
                        <ul className="flex flex-row items-center gap-2">
                            {pageFilterOptions.map(pageFilterOption => (
                                <li key={pageFilterOption}
                                    onClick={() => setItemsPerPage(pageFilterOption)} className={`border border-primary pagination-top-link ${selectedPageOption === pageFilterOption ? "text-other bg-brand border-brand pointer-events-none" : "hover:text-brand hover:border-brand "}`}>
                                    {pageFilterOption}
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
                <div className="flex flex-row justify-between ">

                    <div className="text-gray-600 whitespace-normal align-middle">
                        <span className="px-2 ml-4 text-sm align-middle p text-primary"> {showingMessage}</span>
                    </div>
                </div>
            </div>
        </div>
        : null
    );
}

export default PageFilter;