import {RBAC} from "rbac";
import {UserRole} from "../models/user.model";

export type UserAccess = {
  insights: {list: boolean};
  users: {list: boolean};
  simCards: {list: boolean; synchronize: boolean};
  devices: {list: boolean; view: boolean};
  services: {
    overview: {list: boolean};
    hubspot: {synchronize: boolean};
  };
  drivers: {list: boolean};
  vehicles: {
    list: boolean;
    add: boolean;
    create: boolean;
    update: boolean;
    device: {
      link: boolean;
      unlink: boolean;
    };
    markForDeletion: boolean;
    markInForRepairs: boolean;
    fleet: {
      list: boolean;
      update: boolean;
      create: boolean;
      view: boolean;
    };
  };
};

async function setupUserRole(roleInfo: UserRole) {
  if (roleInfo) {
    // console.log({ roleInfo });
    const {roles, permissions, grants} = roleInfo;
    const rbac = new RBAC({roles, permissions, grants});
    await rbac.init();
    const userRole = await rbac.getRole(roles[0]);
    // console.log({ userRole });
    return userRole;
  } else {
    return undefined;
  }
}

export function defaultUserAccess(): UserAccess {
  return {
    insights: {
      list: false
    },
    users: {
      list: false
    },
    simCards: {
      list: false,
      synchronize: false
    },
    devices: {
      list: false,
      view: false
    },
    services: {
      overview: {list: false},
      hubspot: {
        synchronize: false
      }
    },
    drivers: {
      list: false
    },
    vehicles: {
      add: false,
      list: false,
      create: false,
      update: false,
      device: {
        link: false,
        unlink: false
      },
      markForDeletion: false,
      markInForRepairs: false,
      fleet: {
        list: false,
        update: false,
        create: false,
        view: false
      }
    }
  };
}

export async function setupUserAccess(roleInfo: UserRole) {
  const access: UserAccess = defaultUserAccess();

  if (roleInfo) {
    const userRole = await setupUserRole(roleInfo);

    if (userRole) {
      access.devices.list = await userRole.can("list", "device");
      access.devices.view = await userRole.can("view", "device");
      access.drivers = await userRole.can("list", "driver");
      access.simCards = {list: await userRole.can("list", "simCard"), synchronize: await userRole.can("synchronize", "simCard")};
      access.users.list = await userRole.can("list", "user");
      access.vehicles.list = await userRole.can("list", "vehicle");
      access.services.overview.list = await userRole.can("overviewList", "service");
      access.services.hubspot = {synchronize: await userRole.can("hubspotSynchronize")};
      access.vehicles.create = await userRole.can("create", "vehicle");
      access.vehicles.update = await userRole.can("update", "vehicle");
      access.vehicles.add = await userRole.can("add", "vehicle");
      access.vehicles.fleet.list = await userRole.can("fleetList", "vehicle");
      access.vehicles.fleet.update = await userRole.can("fleetUpdate", "vehicle");
      access.vehicles.fleet.create = await userRole.can("fleetCreate", "vehicle");
      access.vehicles.fleet.view = await userRole.can("fleetView", "vehicle");
      access.vehicles.device.link = await userRole.can("linkDevice", "vehicle");
      access.vehicles.device.unlink = await userRole.can("unlinkDevice", "vehicle");
      access.vehicles.markForDeletion = await userRole.can("markForDeletion", "vehicle");
      access.vehicles.markInForRepairs = await userRole.can("markInForRepairs", "vehicle");
    }
    // console.log({ roleInfoActions: roleInfo?.actions.length });
  }

  return access;
}
