import React from "react";
import { dateDisplay, serviceBackgroundStageColor, stringToCurrency } from "../../core/utilities";
import ServiceCardLineDisplay, { ServiceCardConditionalLineDisplay } from "../service/ServiceCardLineDisplay";

type LicensingFineManagementCardProps = {
  name: string, status: string, vehicleReg: string, makeModel: string, description: string, dateExpired: Date, dateRenewed:Date, costOfRenewal:number
}

const LicensingFineManagementCard = ({ name, status, vehicleReg, makeModel, description, dateExpired, dateRenewed, costOfRenewal }: LicensingFineManagementCardProps) => {
  return (
    <div className="service-card section-container">
      <span className={`alert-status ${serviceBackgroundStageColor(status)}`}>{status}</span>

      <h3 className="text-xl font-semibold">{name}</h3>
      <div className="flex flex-col gap-2 text-sm">
      <ServiceCardConditionalLineDisplay heading={"Make & Model"} details={makeModel}  />
      <ServiceCardLineDisplay heading={"Vehicle Reg"} details={vehicleReg} />
      <ServiceCardLineDisplay heading={"Description:"} details={description}  />
        <div className="flex flex-row gap-4 text-sm">
          {dateExpired ? (
            <p>
              Date Expired: <span className="font-semibold text-brand">{dateDisplay(dateExpired)}</span>
            </p>
          ) : null}
          {dateRenewed ? (
            <p>
              Date Renewed: <span className="font-semibold text-brand">{dateDisplay(dateRenewed)}</span>
            </p>
          ) : null}
          {costOfRenewal ? (
            <p>
              Renewal Cost: <span className="font-semibold text-brand">{stringToCurrency(costOfRenewal)}</span>
            </p>
          ) : null}
        </div>
      </div>

      <button className="w-full mt-4 rounded-lg btn-primary">View Details</button>
    </div>
  );
};

export default LicensingFineManagementCard;
