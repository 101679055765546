import { UserRoleAccess } from "../../../constant";
import TableDataTypes from "./TableDataTypes";
import SimIcon from "../../icons/SimIcon";
import CarIcon from "../../icons/CarIcon";
import PhoneDeviceIcon from "../../icons/PhoneDeviceIcon";

export default class SystemTables {
  static dashboardCameraFeeds = [
    { key: "makeAndModel", label: "Make Model", dataSort: true },
    { key: "device_id", label: "Device Id", dataSort: true },
    { key: "vehicleRegistrationNumber", label: "Vehicle Registration", dataSort: true },
    {
      key: "image_url",
      label: "Feed",
      dataType: TableDataTypes.ImageVideoModal,
      dataSort: false,
      dataObject: { url: "image_url", alt: "cameraFeed", headerOne: "makeAndModel", headerTwo: "vehicleRegistrationNumber" }
    },
    { key: "last_camera_footage_at", label: "Feed DateTime", dataType: TableDataTypes.DateTime, dataSort: true },
    { key: "last_reported_at", label: "Reported DateTime", dataType: TableDataTypes.DateTime, dataSort: true }
  ];

  static dashboardLiveDataFeed = [
    { key: "makeAndModel", label: "Make Model", dataSort: true },
    { key: "vehicleRegistrationNumber", label: "Vehicle Registration", dataSort: true, link: { path: "/vehicles/{id}", id: "id" } },
    { key: "gps_speed", label: "Speed", dataSort: true },
    { key: "vehicle_type", label: "Vehicle Type", dataSort: true },
    { key: "last_reported_at", label: "Reported DateTime", dataType: TableDataTypes.DateTime, dataSort: true }
  ];

  static devices = {
    name: "devices",
    columnOptions: [
      { key: "id", label: "Id", dataSort: true, dataIdentifier: true, canHide: true, link: { path: "/devices/{id}", id: "id" }, icon: <PhoneDeviceIcon /> },
      { key: "primary_sim_iccid", label: "Prim Sim IccId", dataSort: true, canHide: true, dangerInfo: { key: "primary_sim_card_active", value: false, message: "Device Sim card is not ACTIVE" }, link: { path: "/sim-cards/{id}", id: "primary_sim_iccid" }, icon: <SimIcon /> },
      { key: "primary_sim_msisdn", label: "Prim Sim Msisdn", dataSort: true, canHide: true, link: { path: "/sim-cards/{id}", id: "primary_sim_iccid" }, icon: <SimIcon /> },
      { key: "vehicleRegistrationNumber", label: "Vehicle Reg", dataSort: true, canHide: true, link: { path: "/vehicles/{id}", id: "vehicle_id" }, icon: <CarIcon /> },
      { key: "serial_number", label: "Serial Number", dataSort: true, canHide: true },
      { key: "firmware_name", label: "Firmware", dataSort: true, canHide: true },
      { key: "vehicle_battery_voltage", label: "Battery Voltage", dataAlign: "right", dataType: TableDataTypes.Hundredth, dataSort: true, canHide: true },
      { key: "lastReportedOrMinimum", label: "Last Reported", dataType: TableDataTypes.DateTimeOrMinimum, dataSort: true, canHide: true },
      { key: "lastDataTimestampOrMinimum", label: "Last Data Timestamp", dataType: TableDataTypes.DateTimeOrMinimum, dataSort: true, canHide: true },
      { key: "dataTimestampDurationInSeconds", label: "Data Backlog Duration", dataType: TableDataTypes.Duration, dataSort: true, canHide: true },
      { key: "lastSeenOrMinimum", label: "Last Seen", dataType: TableDataTypes.DateTimeOrMinimum, dataSort: true, canHide: true },
      { key: "manufacturerApiLastOnlineOrMinimum", label: "Last Ext Api Online", dataType: TableDataTypes.DateTimeOrMinimum, dataSort: true, canHide: true },
      { key: "vehicle_is_in_for_repair", label: "In For Repairs", dataType: TableDataTypes.Boolean, dataSort: true, canHide: true },
    ]
  };

  static deviceEvents = {
    name: "deviceEvents",
    columnOptions: [
      { key: "event_id", label: "Id", dataSort: true, dataIdentifier: true },
      { key: "vehicleRegistrationNumber", label: "Vehicle Reg", dataSort: true, link: { path: "/vehicles/{id}", id: "vehicle_id" } },
      { key: "make", label: "Make", dataSort: true },
      { key: "model", label: "Model", dataSort: true },
      { key: "device_type", label: "Device Type", dataSort: true },
      { key: "event_type", label: "Event Type", dataSort: true },
      { key: "event_code", label: "Code", dataSort: true },
      { key: "event_value", label: "Value", dataSort: true },
      { key: "description", label: "Description", dataSort: true },
      { key: "resolved", label: "Resolved", dataSort: true, dataType: TableDataTypes.Boolean, excludeOnActive: true },
      { key: "override", label: "Overwritten", dataSort: true, dataType: TableDataTypes.Boolean, excludeOnActive: true },
      { key: "data_timestamp", label: "Reported", dataType: TableDataTypes.DateTime, dataSort: true }
    ]
  };

  static deviceSmsAlerts = [
    { key: "id", label: "Device Id", dataSort: true, dataIdentifier: true },
    { key: "vehicleRegistrationNumber", label: "Vehicle Reg", dataSort: true, link: { path: "/vehicles/{id}", id: "vehicle_id" } },
    { key: "make", label: "Make", dataSort: true },
    { key: "model", label: "Model", dataSort: true },
    { key: "device_type", label: "Device Type", dataSort: true },
    { key: "alert_type", label: "Alert Type", dataSort: true },
    { key: "alert_value", label: "Alert Code", dataSort: true },
    { key: "data_timestamp", label: "Reported", dataType: TableDataTypes.DateTime, dataSort: true }
  ];

  static deviceCameraFeeds = [
    { key: "camera_trigger", label: "Trigger", dataSort: true },
    { key: "image_description", label: "Description", dataSort: true },
    {
      key: "image_url",
      label: "Feed",
      dataType: TableDataTypes.ImageVideoModal,
      dataSort: false,
      dataObject: { url: "image_url", alt: "camera_used", headerOne: "makeAndModel", headerTwo: "vehicleRegistrationNumber" }
    },
    { key: "timestamp", label: "Captured", dataType: TableDataTypes.DateTime, dataSort: true },
    { key: "created_at", label: "Acquired at", dataType: TableDataTypes.DateTime, dataSort: true }
  ];

  static deviceTripPoints = [
    { key: "id", label: "Id", dataSort: true, dataIdentifier: true, click: { id: "id", data: "json_avl_data" } },
    { key: "event_priority", label: "Priority", dataSort: true },
    { key: "event_type", label: "Event Type", dataSort: true },
    { key: "latitudeLongitude", label: "Latitude Longitude", dataSort: true },
    { key: "angle", label: "Angle", dataSort: true },
    { key: "altitude", label: "Altitude", dataSort: true },
    { key: "data_timestamp", label: "Data Timestamp", dataType: TableDataTypes.DateTime, dataSort: true },
    { key: "timestamp", label: "Received Timestamp", dataType: TableDataTypes.DateTime, dataSort: true },
    { key: "dataDuration", label: "Data Duration", dataSort: true },
    { key: "battery_voltage", label: "Battery", dataSort: true, dataType: TableDataTypes.Hundredth },
    { key: "ignition_on", label: "Ignition", dataType: TableDataTypes.Boolean, dataSort: true },
    { key: "movement", label: "Movement", dataType: TableDataTypes.Boolean, dataSort: true },
    { key: "gps_speed", label: "Gps Speed", dataSort: true },
    { key: "gsm_rssi", label: "Gsm Signal", dataSort: true }
  ];

  static simCards = {
    name: "simCards",
    columnOptions:[
    { key: "iccid", label: "IccId", dataSort: true, dataIdentifier: true, link: { path: "/sim-cards/{id}", id: "iccid" } },
    { key: "imsi", label: "Imsi", dataSort: true, link: { path: "/sim-cards/{id}", id: "iccid" } },
    { key: "msisdn", label: "Msisdn", dataSort: true, link: { path: "/sim-cards/{id}", id: "iccid" } },
    { key: "network_status", label: "Network Status", dataSort: true },
    { key: "data_balance_mb", label: "Data Balance MB", dataAlign: "right", dataType: TableDataTypes.Hundredth, dataSort: true },
    { key: "airtime_balance", label: "Airtime Balance", dataAlign: "right", dataType: TableDataTypes.Hundredth, dataSort: true },
    { key: "sms_balance", label: "SMS Balance", dataAlign: "right", dataType: TableDataTypes.Hundredth, dataSort: true },
    { key: "ip_address", label: "IP Address", dataSort: true },
    { key: "synchronized_at", label: "Synch DateTime", dataType: TableDataTypes.DateTime, dataSort: true }
  ]
};

  static simCardUsageLogs = [
    { key: "column_name", label: "Change", dataSort: true, dataIdentifier: true },
    { key: "old_value", label: "Old Value", dataSort: true, dataType: TableDataTypes.Hundredth },
    { key: "new_value", label: "New Value", dataSort: true, dataType: TableDataTypes.Hundredth },
    { key: "used_mb", label: "Used Data(MB)", dataSort: true, dataType: TableDataTypes.Hundredth },
    { key: "used_airtime", label: "Used Airtime", dataSort: true, dataType: TableDataTypes.Hundredth },
    { key: "is_recharge", label: "Recharge", dataSort: true, dataType: TableDataTypes.Boolean },
    { key: "is_zero_balance", label: "Zero Balance", dataAlign: "right", dataType: TableDataTypes.Boolean, dataSort: true },
    { key: "is_negative_balance", label: "Negative Balance", dataAlign: "right", dataType: TableDataTypes.Boolean, dataSort: true },
    { key: "change_date", label: "Change DateTime", dataType: TableDataTypes.DateTime, dataSort: true }
  ];

  static simCardAirtimeDailyUsage = [
    { key: "used_airtime", label: "Used Airtime", dataSort: true, dataIdentifier: true, dataType: TableDataTypes.Hundredth },
    { key: "day_date", label: "Date", dataSort: true, dataType: TableDataTypes.Date }
  ];

  static simCardDataDailyUsage = [
    { key: "used_airtime", label: "Used Data (MB)", dataSort: true, dataIdentifier: true, dataType: TableDataTypes.Hundredth },
    { key: "day_date", label: "Date", dataSort: true, dataType: TableDataTypes.Date }
  ];

  static users = {
    name: "users",
    columnOptions: [
      { key: "id", label: "", dataSort: false, isShown: false, dataIdentifier: true },
      { key: "email_address", label: "Email Address", dataSort: true },
      { key: "first_name", label: "First Name", dataSort: true },
      { key: "last_name", label: "Last Name", dataSort: true },
      { key: "designation", label: "Designation", dataSort: true },
      { key: "user_role_display_name", label: "User Role", dataSort: true },
      { key: "last_dashboard_login", label: "Last Online", dataType: TableDataTypes.DateTime, dataSort: true },
      { key: "enable_remote_log", label: "Remote Log Enabled", dataType: TableDataTypes.EditableBoolean, dataSort: false }
    ]
  };

  static userRegistrations = [
    { key: "email_address", label: "Email Address", dataSort: true, dataIdentifier: true },
    { key: "first_name", label: "First Name", dataSort: true },
    { key: "last_name", label: "Last Name", dataSort: true },
    { key: "designation", label: "Designation", dataSort: true },
    { key: "created_at", label: "Date Created", dataType: TableDataTypes.DateTime, dataSort: true },
    { key: "registration_key_expiry", label: "Expiry Date", dataType: TableDataTypes.DateTime, dataSort: true },
    { key: "reset_expiry", label: "Reset Expiry", dataSort: false, dataType: TableDataTypes.Button, excludeHeader: true },
    { key: "resend_email", label: "Resend Email", dataSort: false, dataType: TableDataTypes.Button, excludeHeader: true }
  ];

  static userAuditTrails = [
    { key: "id", label: "", dataSort: false, isShown: false, dataIdentifier: true },
    { key: "description", label: "Activity Description", dataSort: true },
    { key: "paramId", label: "Activity Record", dataSort: true },
    { key: "browserName", label: "Browser Used", dataSort: true },
    { key: "dashboardUrl", isShown: false },
    { key: "timestamp", label: "Activity DateTime", dataType: TableDataTypes.DateTime, dataSort: true }
  ];

  static vehiclesBatteryInfo = [
    { key: "id", label: "Id", dataSort: true, dataIdentifier: true },
    { key: "make", label: "Make", dataSort: true },
    { key: "model", label: "Model", dataSort: true },
    { key: "veh_registration_no", label: "Vehicle Reg", dataSort: true, dataType: TableDataTypes.VehicleRegistration, link: { path: "/vehicles/{id}", id: "id" } },
    { key: "battery_type", label: "Battery Type", dataSort: true },
    { key: "battery_voltage", label: "Battery Voltage", dataAlign: "right", dataType: TableDataTypes.Hundredth, dataSort: true },
    { key: "battery_capacity", label: "Battery Capacity", dataAlign: "right", dataType: TableDataTypes.Percentage, dataSort: true },
    { key: "last_reported_at", label: "Last Reported", dataType: TableDataTypes.DateTime, dataSort: true }
  ];

  static vehicleFleets = {
    name: "vehicleFleets",
    columnOptions: [
      { key: "id", label: "Id", dataSort: true, dataIdentifier: true },
      { key: "name", label: "Name", dataSort: true, link: { path: "/vehicles/vehicles-fleets/{id}", id: "id" } },
      { key: "description", label: "Description", dataSort: true },
      { key: "vehicle_count", label: "Number of Vehicles", dataSort: true },
    ]
  };
}
