import { FaSimCard } from "react-icons/fa6";
import React from 'react'

function SimIcon() {
    return (
        <FaSimCard />
    )
}

export default SimIcon
