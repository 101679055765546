import {Navigate, Outlet, useLocation} from "react-router-dom";
import {Loading} from "../../components";
import useHasAccessToFeature from "../../hooks/useHasAccessToFeature";
import {RoleFeature} from "../../models/access.model";

/*
RequireAuth.PropTypes = {
  feature: PropTypes.shape({action: PropTypes.string, name: PropTypes.string})
};*/

interface IRequireAuthProps {
  roleFeature?: RoleFeature;
}
function RequireAuth({roleFeature}: IRequireAuthProps) {
  const {hasAccess: isAuthorized, isSignedIn} = useHasAccessToFeature(roleFeature);
  const location = useLocation();
  // console.log({ isAuthorized, isSignedIn, location,feature });

  if (!isSignedIn) {
    return <Navigate to="/login" state={{from: location, isSignedIn}} replace />;
  }

  if (isAuthorized === null) {
    return <Loading loadingText="Loading, please wait" isLoading={true} isLogin={true} />;
  }

  if (isAuthorized) {
    return <Outlet />;
  }

  return <Navigate to="/" replace />;

  // return isSignedIn === false ? <Navigate to="/login" state={{ from: location, isSignedIn }} replace /> :
  //   isAuthorized ? <Outlet /> :
  //   isAuthorized === false ? <Navigate to="/" replace /> : null;
}

export default RequireAuth;
