import React from "react"
import ToastSuccess from "./ToastSuccess";
import ToastFail from "./ToastFail";

type ToastProps = {
  success: boolean,
  message: string,
  show:boolean
}

function Toast({success,message, show}:ToastProps) {
  return (
    success ? <ToastSuccess message={message} show={show} /> : <ToastFail message={message} show={show}/>
  )
}

export default Toast