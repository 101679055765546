import React, { useEffect, useState } from "react";

import DataCardSection, { ILabelValuePairProps } from "../../DataCardSection";
import { DateTimeToHourDayCategoryType } from "../../../models/other.model";
import { displayFromDateTimeCategoryToDateTime, dateTimeCategoryTextColour } from "../../../core/utilities";
import { FaRoadCircleCheck } from "react-icons/fa6";
import { LiaUndoAltSolid } from "react-icons/lia";

interface IVehicleDataCardSectionProps {
  id: number;
    isInForRepairs: boolean;
    isMarkedForDeletion: boolean;
    ignitionOn: string;
    odometer: number;
    onTheRoad: string;
    speed: number;
    movement: boolean;
    batteryVoltage: number;
    batteryCapacity: number;
  lastReported: DateTimeToHourDayCategoryType;
  canClearMarkForDeletion: boolean;
  canClearInForRepairs: boolean;
  onClearInForRepairs?: (id: number) => void;
  onClearMarkForDeletion?: (id: number) => void;
}

function VehicleDataCardSection({
  id,
    isInForRepairs,
    isMarkedForDeletion,
    ignitionOn,
    odometer,
    speed,
    movement,
    onTheRoad,
    batteryVoltage,
    batteryCapacity,
  lastReported,
  canClearMarkForDeletion,
  canClearInForRepairs,
  onClearInForRepairs,
  onClearMarkForDeletion
  
}: IVehicleDataCardSectionProps) {
  const [sectionData, setSectionData] = useState<ILabelValuePairProps[]>([]);
  const title = "Vehicle Status";

  useEffect(() => {
      const data: ILabelValuePairProps[] = [
        {
            label: "In For Repairs",
            value: isInForRepairs ? "Yes" : "",//if true, display yes, else display nothing
            valueColour: isInForRepairs ? "text-delete" : "text-brand"
        },
        {
            label: "Marked For Deletion",
            value: isMarkedForDeletion ? "Yes" : "",//if true, display yes, else display nothing
            valueColour: isMarkedForDeletion ? "text-delete" : "text-brand"
        },
      {
        label: "Ignition On",
        value: ignitionOn,
        valueColour: ignitionOn && ignitionOn.toUpperCase() === "ON" ? "text-brand" : "text-secondary"
        },
        {
            label: "Speed",
            value: speed ? `${speed?.toString()} km/h` : "",
            valueColour: speed === 0 ? "text-secondary" : "text-brand"
        },
        {
            label: "Movement",
            value: movement ? "Yes" : "No",
            valueColour: movement ? "text-brand" : "text-secondary"
        },
        {
            label: "Battery Voltage",
            value: batteryVoltage?.toString(),
            valueColour: batteryCapacity < 50 ? "text-delete" : "text-brand"
        },
      {
        label: "Odometer",
        value: odometer?.toString(),
        copyable: true
      },
      {
        label: "On The Road",
        value: onTheRoad
        },
        {
            label: "Last Reported",
            value: displayFromDateTimeCategoryToDateTime(lastReported),
            valueColour: dateTimeCategoryTextColour(lastReported?.categoryInfo?.name || "")
          },
    ];

    if (isInForRepairs && canClearInForRepairs) { 
      const index = data.findIndex((d) => d.label === "In For Repairs");
      if (index > -1 && onClearInForRepairs)   {
        const valueAction = {
          title: "Clear In For Repairs",
          icon: FaRoadCircleCheck,
          iconColour: "text-brand",
          onClick: () => onClearInForRepairs(id)
        }
        data[index] = {
          ...data[index],
          valueActions: [...(data[index].valueActions || []), ...[valueAction]]
        };
      }
    }

    if (isMarkedForDeletion && canClearMarkForDeletion) { 
      const index = data.findIndex((d) => d.label === "Marked For Deletion");
      console.log(index)
      if (index > -1 && onClearMarkForDeletion)   {
        const valueAction = {
          title: "Clear Marked For Deletion",
          icon: LiaUndoAltSolid,
          iconColour: "text-brand",
          onClick: () => onClearMarkForDeletion(id)
        }
        data[index] = {
          ...data[index],
          valueActions: [...(data[index].valueActions || []), ...[valueAction]]
        };
      }
    }

    setSectionData(data);
  }, [ignitionOn, odometer, onTheRoad, speed, movement, batteryVoltage, batteryCapacity, lastReported, isInForRepairs, canClearInForRepairs]);

  return <DataCardSection title={title} data={sectionData} /> ;
}

export default VehicleDataCardSection;
