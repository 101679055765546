import {api} from "./api";

export const simControlApi = api.injectEndpoints({
  endpoints: builder => ({
    synchronizeSimCards: builder.mutation({
      query: () => ({
        url: "/simControl/simCards/synchronize",
        method: "GET"
      })
    }),
    updateSimCards: builder.query({
      query: () => "/simControl/simCards/update"
    })
  })
});

export const {useSynchronizeSimCardsMutation, useUpdateSimCardsQuery} = simControlApi;
