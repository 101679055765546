import htmlParse from "html-react-parser";
import Api from "../../../api";
import DeviceDataCardSection from "../../../components/device/dataCardSection/DeviceDataCardSection";
import BoxArchiveIcon from "../../../components/icons/BoxArchiveIcon";
import SimCardDataCardSection from "../../../components/simCard/dataCardSection/SimCardDataCardSection";
import SimCardManufactureDataCardSection from "../../../components/simCard/dataCardSection/SimCardManufactureDataCardSection";
import Timeline from "../../../components/Timeline";
import {dateTimeDisplay} from "../../../core/utilities";

const SimCardOverview = ({simCard}) => {
  const {data: simCardLogs = []} = Api.simCards.useGetSimCardLogsQuery(simCard.iccid);
  const {data: device} = Api.devices.useGetDeviceQuery(simCard.deviceId, {skip: !simCard.deviceId});

  const activeAlertsFound = false; // device.activeAlerts;


  const isSimCardInstalled = simCard.deviceId && simCard.deviceId > 0;

  const isDeviceInstalled = simCard.vehicle_id > 0;

  const deviceLogsTimeLine = () => {
    return simCardLogs?.map(deviceLog => {
      const showOldValue = deviceLog.column_name === "primary_sim_iccid" || deviceLog.column_name === "primary_sim_imsi" ? true : false;
      const prefix = deviceLog.column_name === "primary_sim_iccid" ? "Iccid" : deviceLog.column_name === "primary_sim_imsi" ? "Imsi" : "";
      return {
        date: dateTimeDisplay(deviceLog.change_date),
        title: `${prefix} ${deviceLog.new_value}`.trim(),
        description: showOldValue ? `Old value was ${deviceLog.old_value}` : ""
      };
    });
  };




  console.log({simCard, simCardLogs, device, isSimCardInstalled});
  return (
    <div>
      <div className="grid grid-cols-1 gap-4 p-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
        <SimCardDataCardSection active={simCard.active} apn={simCard.apn} iccid={simCard.iccid} msisdn={simCard.msisdn} id={simCard.external_id} isSimCardInstalled={isSimCardInstalled} />
        <SimCardManufactureDataCardSection id={simCard.external_id} networkStatus={simCard.network_status} dataBalance={simCard.data_balance_mb} airtimeBalance={simCard.airtime_balance} smsBalance={simCard.sms_balance} />
        <DeviceDataCardSection active={device?.active} imei={device?.id} hardwareVersion={device?.hardware_version} firmwareName={device?.firmware_name} serialNumber={device?.serial_number} enableDeviceLink={true} isDeviceInstalled={isDeviceInstalled} />
      </div>

      <section className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-2">
        {activeAlertsFound ? (
          <div className="section-container vertical-auto-scrollbar max-h-60 lg:col-span-3" tabIndex="4">
            <h2 className="font-semibold">Active Alerts</h2>
            <ul className="ml-8 list-disc">
              {simCard.activeAlerts?.map((alert, index) => (
                <li key={index}>{htmlParse(`${alert.description} on <i>${dateTimeDisplay(alert.data_timestamp)}</i>`)}</li>
              ))}
            </ul>
          </div>
        ) : null}

        <div className="section-container lg:col-span-3" tabIndex="4">
          <h2 className="font-semibold">Sim Card Logs</h2>
          <Timeline items={deviceLogsTimeLine()} />

          {simCardLogs.length === 0 ? (
            <p className="inline-flex gap-2">
              {" "}
              <BoxArchiveIcon classes={"fill-current"} /> No Logs
            </p>
          ) : null}
        </div>
      </section>
    </div>
  );
};

export default SimCardOverview;
