import React from "react";
import { DashBoardIcon, DriverIcon, GpsIcon, InsightsIcon, RepairServiceIcon, SettingIcon, SimCardIcon, UserIcon, VehicleIcon } from "../icons/SidebarIcons";

export const MenuIcons = Object.freeze({
  DASHBOARD: "DashBoardIcon",
  DRIVER: "DriverIcon",
  INSIGHTS: "InsightsIcon",
  GPS: "GpsIcon",
  REPAIR_SERVICE: "RepairServiceIcon",
  SETTINGS: "SettingIcon",
  SIM_CARD: "SimCardIcon",
  USER: "UserIcon",
  VEHICLE: "VehicleIcon"
});

export function SidebarMenuIcon({ menuIcon, isActive }) {
  if (menuIcon === MenuIcons.DASHBOARD) {
    return <DashBoardIcon classes={`sidebar-icon group-hover:fill-sidebar-other ${isActive ? "fill-sidebar-other" : "fill-sidebar-link"}`} />;
  }

  if (menuIcon === MenuIcons.DRIVER) {
    return <DriverIcon classes={`sidebar-icon group-hover:fill-sidebar-other ${isActive ? "fill-sidebar-other" : "fill-sidebar-link"}`} />;
  }

  if (menuIcon === MenuIcons.INSIGHTS) {
    return <InsightsIcon classes={`sidebar-icon group-hover:fill-sidebar-other ${isActive ? "fill-sidebar-other" : "fill-sidebar-link"}`} />;
  }

  if (menuIcon === MenuIcons.GPS) {
    return <GpsIcon classes={`sidebar-icon group-hover:fill-sidebar-other ${isActive ? "fill-sidebar-other" : "fill-sidebar-link"}`} />;
  }

  if (menuIcon === MenuIcons.SETTINGS) {
    return <SettingIcon classes={`sidebar-icon group-hover:fill-sidebar-other ${isActive ? "fill-sidebar-other" : "fill-sidebar-link"}`} />;
  }

  if (menuIcon === MenuIcons.REPAIR_SERVICE) {
    return <RepairServiceIcon classes={`sidebar-icon group-hover:fill-sidebar-other ${isActive ? "fill-sidebar-other" : "fill-sidebar-link"}`} />;
  }

  if (menuIcon === MenuIcons.SIM_CARD) {
    return <SimCardIcon classes={`sidebar-icon group-hover:fill-sidebar-other ${isActive ? "fill-sidebar-other" : "fill-sidebar-link"}`} />;
  }

  if (menuIcon === MenuIcons.USER) {
    return <UserIcon classes={`sidebar-icon group-hover:fill-sidebar-other ${isActive ? "fill-sidebar-other" : "fill-sidebar-link"}`} />;
  }

  if (menuIcon === MenuIcons.VEHICLE) {
    return <VehicleIcon classes={`sidebar-icon group-hover:fill-sidebar-other ${isActive ? "fill-sidebar-other" : "fill-sidebar-link"}`} />;
  }

  throw new Error(`Invalid menuIcon :${menuIcon}`);
}
