
import { dateTimeCategoryTextColour, displayFromDateTimeCategoryToDateTime } from "../../../core/utilities";
import { DateTimeToHourDayCategoryType } from "../../../models/other.model";
import DataCardSection, { ILabelValuePairProps } from "../../DataCardSection";

interface IDeviceConnectivityDataCardSectionProps {
  lastReported: DateTimeToHourDayCategoryType;
  dataTimestamp: DateTimeToHourDayCategoryType;
  lastSeen: DateTimeToHourDayCategoryType;
  manufactureStatus: string;
  manufacturerApiLastOnline: DateTimeToHourDayCategoryType;
  manufacturerApiLastSync: DateTimeToHourDayCategoryType;
  manufacturerApiFirstLogin: string;
}




function DeviceConnectivityDataCardSection({ lastReported, dataTimestamp, lastSeen, manufactureStatus, manufacturerApiFirstLogin, manufacturerApiLastOnline, manufacturerApiLastSync }: IDeviceConnectivityDataCardSectionProps) {
  const title = "Device Connectivity";
  const sectionData: ILabelValuePairProps[] = [
    {
      label: "Reported",
      value: displayFromDateTimeCategoryToDateTime(lastReported),
      valueColour: dateTimeCategoryTextColour(lastReported.categoryInfo?.name || "")
    },
    {
      label: "Data Timestamp",
      value: displayFromDateTimeCategoryToDateTime(dataTimestamp),
      valueColour: dateTimeCategoryTextColour(dataTimestamp.categoryInfo?.name || "")
    },
    {
      label: "Last Seen",
      value: displayFromDateTimeCategoryToDateTime(lastSeen),
      valueColour: dateTimeCategoryTextColour(lastSeen.categoryInfo?.name || "")
    },
    {
      label: "Manufacturer Status",
      value: manufactureStatus
    },
    {
      label: "Manufacturer API Last Online",
      value: displayFromDateTimeCategoryToDateTime(manufacturerApiLastOnline),
      valueColour: dateTimeCategoryTextColour(manufacturerApiLastOnline.categoryInfo?.name || "")
    },
    {
      label: "Manufacturer API Last Sync",
      value: displayFromDateTimeCategoryToDateTime(manufacturerApiLastSync),
      valueColour: dateTimeCategoryTextColour(manufacturerApiLastSync.categoryInfo?.name || "")
    },
    {
      label: "Manufacturer API First Login",
      value: manufacturerApiFirstLogin
    }
  ];

  return (
    <div className="flex">
      <DataCardSection title={title} data={sectionData} />
    </div>
  );
}

export default DeviceConnectivityDataCardSection;
