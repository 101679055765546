import {getFieldFromObject} from "./utilities";

export function getCategoryTotals({data, fieldName, colorScheme, defaultUndefinedLabel = "Unknown", defaultColour = "#90EE90"}) {
  const fieldTotals = data.reduce(function (accumulator, item) {
    const field = getFieldFromObject(item, fieldName) || defaultUndefinedLabel;
    const value = accumulator[field]?.value || 1;

    if (!accumulator[field]) {
      accumulator[field] = {
        label: field,
        value: value
      };

      if (colorScheme && colorScheme[field]) {
        accumulator[field].colour = colorScheme[field];
      } else {
        accumulator[field].colour = defaultColour;
      }
    } else {
      accumulator[field].value++;
    }

    return accumulator;
  }, {});

  const sortOrder = Object.keys(colorScheme);
  if (sortOrder) {
    const sortedTotals = {};
    //Sort by color
    sortOrder.forEach(function (key) {
      if (fieldTotals.hasOwnProperty(key)) {
        sortedTotals[key] = fieldTotals[key];
      }
    });

    //add those that are not sorted
    Object.keys(fieldTotals).forEach(function (key) {
      if (!Object.keys(sortedTotals).includes(key)) {
        sortedTotals[key] = fieldTotals[key];
      }
    });
    return Object.values(sortedTotals);
  }
  return Object.values(fieldTotals);

  function getFieldFromObject(object, propertyName) {
    var parts = propertyName.split(".");
    let result = object;
    for (var i = 0; i < parts.length; i++) {
      result = result[parts[i]];
    }

    return result;
  }
}

export function getCategoryInfoTotals({data, fieldName, defaultColour = "#CFCFCF"}) {
  const fieldTotals = data.reduce(function (accumulator, item) {
    const field = getFieldFromObject(item, fieldName) || {label: "Unknown", value: 0, order: 9999};
    const value = accumulator[field.label]?.value || 1;

    if (!accumulator[field.label]) {
      accumulator[field.label] = {
        label: field.label,
        order: field.order,
        value: value
      };

      if (field.colour) {
        accumulator[field.label].colour = field.colour;
      } else {
        accumulator[field.label].colour = defaultColour;
      }
    } else {
      accumulator[field.label].value++;
    }

    return accumulator;
  }, {});

  return Object.values(fieldTotals).sort((a, b) => a.order - b.order);
}

/* Table options */
export function getTableRenderSizePerPageOptionsDropDown(props, recordCount) {
  const roundedUpCount = Math.ceil(recordCount / 100) * 100;
  let options = [10, 25, 50, 75, 100, 250, 500, 750];
  let lastGreaterThanValue = null;
  const minOption = recordCount > 10 ? Math.ceil(recordCount / 25) * 25 : 10;

  if (roundedUpCount > options[options.length - 1]) {
    options = options.filter(option => option < minOption || option >= minOption);
    options.push(roundedUpCount);
  } else {
    options = options.filter(option => {
      if (lastGreaterThanValue === null) {
        lastGreaterThanValue = option > minOption ? option : null;
        return option <= minOption || option > minOption;
      }
      return false;
    });
  }

  return (
    <div className="btn-group">
      {options.map((n, idx) => {
        const isActive = n === Number(props.currSizePerPage) ? "active" : null;
        let optionName = n;
        if (roundedUpCount === n && idx + 1 === options.length) {
          optionName = "All";
        }

        return (
          <button key={idx} type="button" className={`btn btn-info ${isActive}`} onClick={() => props.changeSizePerPage(n)}>
            {optionName}
          </button>
        );
      })}
    </div>
  );
}

export async function getBrowserInfoByUserAgent() {
  const userAgent = window.navigator.userAgent;
  const brave = window.navigator.brave;
  const browserInfo = {browserName: "", linkInfo: ""};
  console.log({userAgent});

  return new Promise(resolve => {
    if (brave) {
      brave.isBrave().then(isBrave => {
        resolve({browserName: `Brave${isBrave ? "" : "?"}`, linkInfo: "https://community.brave.com/t/is-it-possible-to-share-only-my-location-on-the-browser-and-sites/487159"});
      });
    } else {
      if (userAgent.includes("Firefox")) {
        browserInfo.browserName = "Mozilla Firefox";
        browserInfo.linkInfo = "https://timewellscheduled.com/eneable-location-sharing-on-firefox-and-google-chrome/";
      } else if (userAgent.includes("Edge") || userAgent.includes("Edg")) {
        setupBrowserInfo("Microsoft Edge", "enabling-microsoft-edge-location-services");
      } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
        setupBrowserInfo("Apple Safari", "enabling-safari-location-services-mac");
      } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
        browserInfo.browserName = "Opera";
        browserInfo.linkInfo = "https://help.opera.com/en/latest/web-preferences/";
      } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
        browserInfo.browserName = "Internet Explorer";
        browserInfo.linkInfo = "https://support.microsoft.com/en-gb/windows/change-security-and-privacy-settings-for-internet-explorer-11-9528b011-664c-b771-d757-43a2b78b2afe";
      } else if (userAgent.includes("Chrome")) {
        setupBrowserInfo("Google Chrome", "enabling-chrome-location-services");
      } else {
        setupBrowserInfo("Unknown Broswer", "");
      }
      console.log(browserInfo);
      resolve(browserInfo);
    }
  });

  function setupBrowserInfo(browserName, urlAnchorLinkTag, url = "") {
    const linkInfoWebsite = "https://docs.buddypunch.com/en/articles/919258-how-to-enable-location-services-for-chrome-safari-edge-and-android-ios-devices-gps-setting";

    browserInfo.browserName = browserName;
    browserInfo.linkInfo = `${linkInfoWebsite}#${urlAnchorLinkTag}`;
  }
}
