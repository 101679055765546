import htmlParse from "html-react-parser";
import { Fragment } from "react";
import Api from "../../api";
import UserRoleAccess from "../../constant/userRoleAccess";
import { cn, dateTimeDisplay, distanceDisplay, durationDisplay, speedDisplay } from "../../core/utilities";
import useHasAccessToFeature from "../../hooks/useHasAccessToFeature";
import DeviceDataCardSection from "../device/dataCardSection/DeviceDataCardSection";
import {VehicleDataCardSection,VehicleStatusDataCardSection} from "../vehicle/dataCardSection";
import { AlertIcon } from "../icons/SidebarIcons";
import SimCardDataCardSection from "../simCard/dataCardSection/SimCardDataCardSection";
import Timeline from "../Timeline";

const VehicleOverview = ({ vehicle, onAsyncProcessStart,hasAccessToDevices }) => {
  
  const {data: device, isLoading,error,isError,status} = Api.devices.useGetDeviceQuery(vehicle.device_id, {skip: !vehicle.device_id || !hasAccessToDevices });
  const [clearInForRepairs] = Api.vehicles.useDeleteVehicleInForRepairsMutation();
  const [markInForRepairs] = Api.vehicles.usePutSetVehicleInForRepairsMutation();
  
  const [clearMarkForDeletion] = Api.vehicles.useDeleteVehicleMarkForDeletionMutation();
  const [markForDeletion] = Api.vehicles.usePutSetVehicleMarkForDeletionMutation();

  const activeAlertsFound = vehicle.activeAlerts;
  const {hasAccess: hasAccessToMarkForRepairs} = useHasAccessToFeature(UserRoleAccess.vehicleMarkInForRepairs);
  const {hasAccess: hasAccessToMarkForDeletion} = useHasAccessToFeature(UserRoleAccess.vehicleMarkForDeletion);
  const {hasAccess: hasAccessToUnlinkVehicle} = useHasAccessToFeature(UserRoleAccess.vehicleUnlinkDevice);

  const vehicleTripsTimeLine = () => {
    return vehicle.trips.map(trip => {
      return {
        date: dateTimeDisplay(trip.start_date_time),
        title: `${distanceDisplay(trip.distance)} driven`,
        description: `Trip took ${durationDisplay(trip.duration, false)}, maximum speed for the trip was ${speedDisplay(trip.maximum_speed)}  and the trip ended at ${dateTimeDisplay(trip.end_date_time)}`,
        link: {path: `/vehicles/vehicle-trip/${vehicle?.id.toString()}/${trip.id.toString()}`, title: "View Trip"}
      };
    });
  };

  const handleClearInForRepairs = async id => {
    onAsyncProcessStart(() => clearInForRepairs(id), "Clear Vehicle from In-Repairs");
  };

  const handleMarkInForRepairs = async id => {
    onAsyncProcessStart(() => markInForRepairs(vehicle.id), "Mark Vehicle from In-Repairs");
  };

  const handleMarkForDeletion = async id => {
    onAsyncProcessStart(() => markForDeletion(vehicle.id), "Mark Vehicle for Deletion");
  };

  const handleClearMarkForDeletion = async id => {
    onAsyncProcessStart(() => clearMarkForDeletion(vehicle.id), "Clear Vehicle from Deletion");
  };

   console.log({vehicle,isLoading,error,isError,status, hasAccessToDevices,device});

  return (
    <div className="flex flex-col gap-4">
      <div className={cn("grid grid-cols-1 gap-4  sm:grid-cols-2",{
        "md:grid-cols-3 lg:grid-cols-4":hasAccessToDevices })}>
          
      <VehicleDataCardSection 
        active={vehicle.active}
          id={vehicle.id}
          vehicleRegistrationNumber={vehicle.vehicleRegistrationNumber}
          manufactureYear={vehicle.year}
          engineNumber={vehicle.engine_number}
          vehicleMakeAndModel={vehicle.vehicleMakeAndModel}
          vin={vehicle.vin}
          batteryVoltage={vehicle.battery_voltage}
          canUnlinkDevice={hasAccessToUnlinkVehicle}
          canMarkInForRepairs={hasAccessToMarkForRepairs && vehicle.is_in_for_repairs !== true}
          canMarkForDeletion={hasAccessToMarkForDeletion && vehicle.is_marked_for_deletion === true}
          canDisableVehicle={false}
          canInitiateVehicleTracking={false}
          onMarkInForRepairs={handleMarkInForRepairs}
          onMarkForDeletion={handleMarkForDeletion}
  />
        <VehicleStatusDataCardSection 
          id={vehicle.id}
          isInForRepairs={vehicle.is_in_for_repairs}
          isMarkedForDeletion={vehicle.is_marked_for_deletion}
          ignitionOn={vehicle.ignitionOn}
          odometer={vehicle.overview.odometer}
          batteryVoltage={vehicle.overview.batteryVoltage}
          batteryCapacity={vehicle.battery_capacity}
          speed={vehicle.speed}
          movement={vehicle.movement}
          lastReported={vehicle.lastReported}
          canClearInForRepairs={hasAccessToMarkForRepairs && vehicle.is_in_for_repairs === true}
          canClearMarkForDeletion={hasAccessToMarkForDeletion && vehicle.is_marked_for_deletion === true}
          onMarkInForRepairs={handleMarkInForRepairs}
          onClearInForRepairs={handleClearInForRepairs}
          onClearMarkForDeletion={handleClearMarkForDeletion}
        />
        {!isLoading && hasAccessToDevices && device ? (
          <Fragment>
            <DeviceDataCardSection isLoading={isLoading} active={device.active} imei={vehicle.device_id} hardwareVersion={device.device_type} firmwareName={device.firmware_name} serialNumber={device?.serial_number} enableDeviceLink={true} isDeviceInstalled={true} />
            <SimCardDataCardSection active={device.primary_sim_card_active} apn={device.primary_sim_cards_apn} iccid={device.primary_sim_iccid} msisdn={device.primary_sim_msisdn} id={device.primary_sim_card_external_id} isSimCardInstalled={device.primary_sim_iccid ? true : false} enableSimLink={true} />
          </Fragment>
        ) : null}
      </div>
      <section className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        
        {activeAlertsFound ? (
          <div className="section-container vertical-auto-scrollbar max-h-60 lg:col-span-3" tabIndex="3">
            <h2 className="font-semibold">Active Alerts</h2>
            <ul className="ml-8 list-disc">
              {vehicle.activeAlerts.map((alert, index) => (
                <li key={index}>{htmlParse(`${alert.description} on <i>${dateTimeDisplay(alert.data_timestamp)}</i>`)}</li>
              ))}
            </ul>
          </div>
        ) : null}

        <div className="section-container lg:col-span-3" tabIndex="4">
          <h2 className="font-semibold">Trips</h2>
          <Timeline items={vehicleTripsTimeLine()} />

          {vehicle.trips.length === 0 ? (
            <p className="inline-flex gap-2">
              {" "}
              <AlertIcon classes={"fill-current"} /> No Trips Found !
            </p>
          ) : null}
        </div>
      </section>
    </div>
  );
};

export default VehicleOverview;
