import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Layout, PrimaryHeader, OnError } from "../../components";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { isValidEmailAddress } from "../../core/utilities";
import Api from "../../api";

export default function UserRegistration() {
  const { data: existingUsersEmails } =
    Api.userRegistrations.useGetForRegistrationAllExistingEmailAddressesQuery();

  return <NewUserRegistration existingUsersEmails={existingUsersEmails} />;
}

function NewUserRegistration({ existingUsersEmails }) {
  const [addNewUser] = Api.users.useAddUserMutation();
  const [formData, setFormData] = useState({
    emailAddress: "",
    firstName: "",
    lastName: "",
    designation: "",
    mobileNumber: "",
  });

  const [checkIfEmailExists] =
    Api.userRegistrations.useLazyGetForRegistrationDoesEmailAddressExistQuery();
  const [errors, setErrors] = useState({});
  const [emailExistsRegisterList, setEmailExistsRegisterList] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [isRikatecUser, setIsRikatecUser] = useState(false);
  const user = useSelector(selectCurrentUser);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const loadText = "Adding User...";

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "emailAddress") {
      const exists = existingUsersEmails.find(
        (user) => user.email_address === value
      );
      setEmailExistsRegisterList(exists);
    }
  };

  const checkEmailExists = async (email) => {
    //console.log({email});

    if (email.trim() !== "" && isValidEmailAddress(email)) {
      try {
        const emailExists = await checkIfEmailExists(email).unwrap();
        //console.log("we get here");

        //const doesEmailExist = await useGetForRegistrationDoesEmailAddressExistQuery(email).unwrap();

        console.log({ emailExists });
      } catch (error) {
        console.error("Error checking email existence:", error, email);
      }
    }
  };

  const handleCheckboxChange = (event) => {
    setIsRikatecUser(event.target.checked);
  };

  const submitNewUserToApi = async (credentials) => {
    return new Promise((resolve, reject) => {
      try {
        if (Object.keys(errors).length === 0) {
          // submit the form

          addNewUser(credentials)
            .unwrap()
            .then((addResults) => {
              if (addResults) {
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
              console.error(error);
              reject(error);
            });
        }
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  };

  const handleSubmitNewUser = async (event) => {
    event.preventDefault();

    const newErrors = {};
    if (formData.emailAddress.trim() === "") {
      newErrors.emailAddress = "Email address is required";
    } else if (!isValidEmailAddress(formData.emailAddress)) {
      newErrors.emailAddress = "Please enter a valid email address";
    } else if (
      existingUsersEmails.find(
        (user) => user.email_address === formData.emailAddress
      )
    ) {
      newErrors.emailAddress = "User already exists in the registrations list";
    } else if (emailExists) {
      newErrors.emailAddress = "User already exists";
    }
    if (formData.firstName.trim() === "") {
      newErrors.firstName = "First name is required";
    }
    if (formData.lastName.trim() === "") {
      newErrors.lastName = "Last name is required";
    }

    setErrors(newErrors);
    setLoading(true);

    if (Object.keys(newErrors).length === 0) {
      try {
        const addResults = await submitNewUserToApi({
          ...formData,
          createdBy: user.id,
          createdAt: new Date(),
        });

        if (addResults) {
          navigate("/users/registrations");
        } else {
          console.error(addResults);
        }
      } catch (error) {
        console.error(error);
      }
    }
    setLoading(false);
  };

  return (
    <Layout isForegroundProcess={loading} loadingText={loadText}>
      <PrimaryHeader title="Add New User">
        <div className="flex"></div>
      </PrimaryHeader>

      <section className="flex flex-col">
        <div className="input-card">
          <form
            onSubmit={handleSubmitNewUser}
            className="flex flex-col w-full items-start"
          >
            {errors.response ? (
              <div className="flex w-full mb-4 text-primary">
                <p className="px-2 text-sm text-red-500">{errors.response}</p>
              </div>
            ) : null}
            <div className="lg:flex-row flex-col flex w-full mb-4 text-primary gap-y-2 lg:gap-y-0 lg:items-center">
              <label htmlFor="emailAddress" className="lg:basis-40">
                Email Address:
              </label>
              <input
                type="email"
                id="emailAddress"
                name="emailAddress"
                value={formData.emailAddress}
                onChange={handleInputChange}
                onBlur={() => {
                  console.log("Blur event triggered");
                  checkEmailExists(formData.emailAddress);
                }}
                className="full-width-input-field"
              />
              {errors.emailAddress ? (
                <p className="pl-1 text-sm text-red-500 ">
                  {errors.emailAddress}
                </p>
              ) : null}
            </div>

            <div className="lg:flex-row flex-col flex w-full mb-4 text-primary gap-y-2 lg:gap-y-0 lg:items-center">
              <label htmlFor="firstName" className="lg:basis-40">
                First Name:
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                className="full-width-input-field"
              />
              {errors.firstName ? (
                <p className="pl-2 text-sm text-red-500">{errors.firstName}</p>
              ) : null}
            </div>
            <div className="lg:flex-row flex-col flex w-full mb-4 text-primary gap-y-2 lg:gap-y-0 lg:items-center">
              <label htmlFor="lastName" className="lg:basis-40">
                Last Name:
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                className="full-width-input-field"
              />
              {errors.lastName ? (
                <p className="pl-2 text-sm text-red-500">{errors.lastName}</p>
              ) : null}
            </div>

            <div className="lg:flex-row flex-col flex w-full mb-4 text-primary gap-y-2 lg:gap-y-0 lg:items-center">
              <label htmlFor="designation" className="lg:basis-40">
                Designation:
              </label>
              <input
                type="text"
                id="designation"
                name="designation"
                value={formData.designation}
                onChange={handleInputChange}
                className="full-width-input-field"
              />
            </div>

            <div className="lg:flex-row flex-col flex w-full mb-4 text-primary gap-y-2 lg:gap-y-0 lg:items-center">
              <label htmlFor="designation" className="lg:basis-40">
                Mobile Number:
              </label>
              <input
                type="tel"
                id="mobileNumber"
                name="mobileNumber"
                value={formData.mobileNumberr}
                onChange={handleInputChange}
                className="full-width-input-field"
              />
            </div>

            {user.isRiiotUser && (
              <div className="lg:flex-row flex-col flex w-full mb-4 text-primary gap-y-2 lg:gap-y-0 lg:items-center">
                <label
                  htmlFor="rikatecUser"
                  className="flex items-center cursor-pointer select-none lg:basis-40"
                >
                  <span className="mr-3">Rikatec User:</span>
                </label>
                <div className="relative w-6 h-6 border-2 rounded-md border-secondary ">
                  <input
                    type="checkbox"
                    id="rikatecUser"
                    name="rikatecUser"
                    checked={isRikatecUser}
                    onChange={handleCheckboxChange}
                    className=" z-10 absolute left-0 right-0 top-0 bottom-0 opacity-0 cursor-pointer"
                  />
                  <div
                    className={`absolute w-full h-full rounded-md ${
                      isRikatecUser ? "bg-brand" : "bg-background"
                    }`}
                  ></div>
                  {isRikatecUser && (
                    <div className="absolute inset-0 flex items-center justify-center text-white">
                      <svg
                        className="w-4 h-4 fill-primary"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M20 6L9 17l-5-5" />
                      </svg>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="w-fit whitespace-nowrap py-4 text-primary">
              <button type="submit" className="w-fit btn-primary">
                Add New User
              </button>
            </div>
          </form>
        </div>
      </section>
    </Layout>
  );
}
