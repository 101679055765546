import React, { Fragment, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useCheckUserPasswordResetStatusQuery, useUserPasswordResetSubmitMutation } from "../api/rtk/baseApi";
import Loading from "../components/Loading";
import PasswordStrengthMeter from "../components/PasswordStrengthMeter";
import systemConfig from "../config/systemConfiguration";
//import { AlertDialog } from "../components";
import { useNavigate } from "react-router-dom";
import AttentionDialog from "../components/modal/AttentionDialog";
import { CustomerEntity } from "../models/entity.model";
import zxcvbn from "zxcvbn";
import getEntityLogo from "../core/entityLogo";
import { useSelector } from "react-redux";
import { selectCurrentTheme } from "../features/settings/settingsSlice";
import { defaultBrowserTheme } from "../core/utilities";

type PasswordResetData  = {
  emailAddress: string,
  password: string,
  entity:  Pick<CustomerEntity, "uuId">
}


function ResetPassword() {
  const [searchParams] = useSearchParams();
  const key = searchParams.get("passwordResetKey");
  const emailAddress = searchParams.get("emailAddress");
  const { data: statusData, isLoading, isError, error } = useCheckUserPasswordResetStatusQuery({ emailAddress, key });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState<zxcvbn.ZXCVBNResult>();
  const [theme] = useState(useSelector(selectCurrentTheme)||defaultBrowserTheme());
  const [passwordReset] = useUserPasswordResetSubmitMutation();
  const { entity } = systemConfig;


  const navigate = useNavigate();
  //const [showAlert, setShowAlert] = useState(false);
  //const [alertMessage, setAlertMessage] = useState("");
  const [showAttentionDialog, setShowAttentionDialog] = useState(false);
  const [attentionMessage, setAttentionMessage] = useState("");

  const submitPasswordReset = async (submitData:PasswordResetData): Promise<{success:boolean}> => {
    return new Promise((resolve, reject) => {
      try {
        passwordReset(submitData)
          .unwrap()
          .then((apiResponse) => {
            resolve(apiResponse);
          })
          .catch((error) => {
            console.error(error);
            setAttentionMessage("An Error Has Occurred. Please Try Again Later Or Contact Rikatec For Support");
            setShowAttentionDialog(true);
            reject(error);
          });
      } catch (error) {
        console.error(error);
        setAttentionMessage("An Error Has Occurred. Please Try Again Later Or Contact Rikatec For Support");
        setShowAttentionDialog(true);
        reject(error);
        
      }
    });
  };

  const handleResetPassword = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (password === confirmPassword && passwordStrength && passwordStrength.score >= 3) {
      console.log("Passwords match! Perform password reset logic.");
      doPasswordReset();
    } else {
      setAttentionMessage("Passwords do not match.");
      setShowAttentionDialog(true);
      console.error("Passwords do not match. Please try again.");
      setAttentionMessage("Passwords do not match or do not meet the strength requirements.");
      setShowAttentionDialog(true);
    }
  };
  const newPassword = password;

  const doPasswordReset = async () => {
    try {
      console.log(emailAddress);
      console.log(entity);
      const submitData = { emailAddress: emailAddress as string, password: newPassword, entity: { uuId: entity.uuId } };

      submitPasswordReset(submitData)
        .then((apiResponse) => {
          console.log(apiResponse);

          if (apiResponse.success) {
            console.log("success");
            setAttentionMessage("Password has been successfully changed");
            setShowAttentionDialog(true);
            /*
            <Fragment>
              <Loading isLoading={true} loadingText={"Invalid Key. Redirecting to Login Page...."} />
              {setTimeout(() => navigate("/login"), 3000)}
            </Fragment>;
            */
          }
        })
        .catch((error) => {
          console.error(error);
          console.log("failed, status: ", error.status, ", message: ", error.data?.message);
          setAttentionMessage("Password has already been used.");
          setShowAttentionDialog(true);
        });
    } catch (error) {
      console.error(error);
      setAttentionMessage("An Error Has Occurred. Please Try Again Later Or Contact Rikatec For Support");
      setShowAttentionDialog(true);
    }
  };

  return isError ? (
    <Fragment>
      <Loading isLoading={true} loadingText={"Invalid Key. Redirecting to Login Page...."} />
      {/*setTimeout(() => navigate("/login"), 3000)*/}
    </Fragment>
  ) : isLoading ? (
    <Loading isLoading={isLoading} loadingText={"Loading Reset Page..."} />
  ) : (
    <section className="flex items-center justify-center w-screen h-screen overflow-hidden bg-background">
      <form className="shadow-xl section-container" autoComplete="off">
        <AttentionDialog onClick={() => setShowAttentionDialog(false)} show={showAttentionDialog} attentionMessage={attentionMessage} />
        <img src={getEntityLogo(systemConfig.entity.acronym, theme)} alt={"logo"} height={150} width={150} className="mx-auto" />

        <h1 className="font-bold text-center">Reset Password</h1>

        <div className="relative">
          <label htmlFor="password" className="block mb-2 text-sm font-medium text-primary">
            Password
          </label>

          <input name="password" id="password" className="input-field" type="password" required autoComplete="off" value={password} onChange={(e) => setPassword(e.target.value)} />

          <div className="flex flex-col gap-4 mt-4" />

          <label htmlFor="confirmPassword" className="block mb-2 text-sm font-medium text-primary">
            Confirm Password
          </label>

          <input
            name="confirmPassword"
            id="confirmPassword"
            className="input-field"
            type="password"
            required
            autoComplete="off"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />

          <div className="flex flex-col gap-4 mt-4" />

          <PasswordStrengthMeter password={password} setStrength={setPasswordStrength} />

          <div className="flex flex-col gap-4 mt-4" />

          <button
            name="reset-password"
            type="submit"
            className={`w-full btn-primary ${passwordStrength && passwordStrength.score >= 3 ? "" : "disabled"}`}
            onClick={handleResetPassword}
            disabled={passwordStrength && passwordStrength.score < 3}
          >
            Reset Password
          </button>

          <div className="flex flex-col gap-4 mt-4" />

          <p className="text-sm">
            Need some support? Contact us at <span className="text-brand">+27 10 822 2240</span>{" "}
          </p>
        </div>
      </form>
    </section>
  );
}

export default ResetPassword;
