import {clsx, type ClassValue} from "clsx";
import {pick, takeWhile, uniq} from "lodash";
import moment from "moment";
import {twMerge} from "tailwind-merge";
import {DateTimeToHourDayCategoryType, SystemTheme,SystemThemeSetting} from "../models/other.model";
var pluralize = require("pluralize");

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

function isNumber(value: number): value is number {
  return typeof value === "number" && !isNaN(value);
}

function isString(value: string | any): value is string {
  return typeof value === "string";
}

function stringToNumberOrNull(str: string): number | null {
  const num = parseFloat(str);
  return isNaN(num) ? null : num;
}

function isValidBoolean(data: any): boolean {
  if (isNullOrUndefined(data)) {
    return false;
  }

  return typeof data === "boolean";
}

function isNullOrUndefined(data: any): boolean {
  return data === null || data === undefined;
}

function titleCase(stringText: string): string {
  var sentence = stringText.toLowerCase().split(" ");
  for (var i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }

  return sentence.join(" ");
}

function validString(data: any): string {
  if (isNullOrUndefined(data)) {
    return "";
  }

  return data;
}

function validNumberOrDefault(data: number | any, defaultValue = 0): number {
  return isValidNumber(data) ? data : defaultValue;
}

function setMomentThresholds() {
  moment.relativeTimeThreshold("s", 59);
  moment.relativeTimeThreshold("m", 59);
  moment.relativeTimeThreshold("h", 23);
  moment.relativeTimeThreshold("d", 28);
}

/**
 * External Functions
 */

interface ICategoryTotalsArgs<T> {
  data: T[];
  fieldName: string;
  colorScheme: any;
  defaultUndefinedLabel?: string;
  defaultColour: string;
}

export function getFieldFromObject<T>(object: T, propertyName: string) {
  var propertyPath = propertyName.split(".");
  let value: any = object;
  for (const prop of propertyPath) {
    if (value && typeof value === "object" && prop in value) {
      value = value[prop as keyof typeof value];
    } else {
      return undefined;
    }
  }

  return value;
}

export function tableCellDateFormatter(cell: Date) {
  if (!cell) {
    return "";
  }
  return `${moment(cell).format("lll") ? moment(cell).format("lll") : moment(cell).format("lll")}`;
}

export function toBoolean(data: any) {
  if (typeof data === "undefined") {
    return false;
  }
  if (typeof data === "object" && isNullOrUndefined(data)) {
    return false;
  }
  if (typeof data === "string" && isNullOrUndefined(data)) {
    return false;
  }

  if (typeof data === "number" && isNullOrUndefined(data)) {
    return false;
  }
  if (typeof data === "boolean" && isNullOrUndefined(data)) {
    return false;
  }

  if (typeof data === "number" && isValidNumber(Number(data))) {
    return Number(data) > 0;
  }

  if (typeof data === "boolean" && isValidBoolean(data)) {
    return data;
  }

  if (typeof data === "string" && isValidString(data)) {
    return stringToBinary(data);
  }
  return false;
}

export function isNotNullOrUndefined(data: unknown) {
  if (data === undefined) {
    return false;
  } else if (data === null) {
    return false;
  }
  return true;
}

export function stringToBinary(data: boolean | string, defaultValue: boolean = false) {
  if (typeof data === "boolean") {
    return data;
  }

  if (isValidString(data)) {
    return data === "1" || (data as string).toLowerCase() === "true";
  }

  return defaultValue;
}

export function setupFullName(...names: string[]) {
  let fullName = "";

  if (isValidArrayWithData(names)) {
    names.forEach(name => {
      if (isNotNullOrUndefined(name)) {
        fullName = `${fullName} ${name}`.trim();
      }
    });
  }
  //console.log(`full name:${fullName};`, names);
  return fullName;
}

export function isValidNumber(data: number) {
  if (isNullOrUndefined(data)) {
    return false;
  }

  if (typeof data === "number") {
    return true;
  }
  if (!isNaN(data)) {
    return true;
  }

  return false;
}
export function isValidString(data: string | null | undefined) {
  if (isNullOrUndefined(data)) {
    return false;
  }

  return typeof data === "string";
}

export function isValidArrayWithData<T>(array: T[]) {
  if (isNullOrUndefined(array)) {
    //console.assert(false, "we got to 183", data);
    return false;
  }

  if (Array.isArray(array)) {
    return array.length > 0;
  }

  return false;
}

export function isValidEmailAddress(emailAddress: string) {
  const regX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regX.test(emailAddress);
}

export function splitAlphaNumeric(alphaNumeric: string) {
  if (isNullOrUndefined(alphaNumeric)) {
    return "";
  }

  if (typeof alphaNumeric !== "string") {
    return alphaNumeric;
  }

  const stringArray = alphaNumeric.match(/[a-zA-Z]+|[0-9]+/g);

  return stringArray ? stringArray.join(" ") : alphaNumeric;
}

export function lastArrayElement<T>(data: T[]) {
  if (isValidArrayWithData(data)) {
    return data[data.length - 1];
  }
}

export function validatedPrimaryKeys(...keys: number[]) {
  let isValidated = false;
  let isAlreadyInvalid = false;

  if (isValidArrayWithData(keys)) {
    keys.forEach(key => {
      if (isAlreadyInvalid) {
        // do nothing
      } else if (isNotNullOrUndefined(key) && isValidNumber(key) && Number(key) > -1) {
        isValidated = true;
      } else {
        isValidated = false;
        isAlreadyInvalid = true;
      }
    });
  }
  // console.log('validated?:', isValidated, keys);
  return isValidated;
}

export function stringToCurrency(data: string | number) {
  return new Intl.NumberFormat("en-ZA", {
    style: "currency",
    currency: "ZAR"
  }).format(validNumberOrDefault(data, 0));
}

export function stringToNumber(data: string, fixedDigits: number, defaultValue: number = 0) {
  if (stringToNumberOrNull(data)) {
    return fixedDigits ? Number(data).toFixed(fixedDigits) : Number(data);
  }

  return defaultValue;
}

export function camelCaseToTitleCase(stringText: string) {
  const result = stringText.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function numberFormat(data: string | number, fixedDigits: number, defaultValue: number = 0) {
  const num = typeof data === "string" ? parseFloat(data) : data;

  if (isNumber(num)) {
    return new Intl.NumberFormat("en-ZA", {
      maximumSignificantDigits: 3
    }).format(validNumberOrDefault(num.toFixed(fixedDigits)));
  }

  return defaultValue;
}


export function dataBasedFormatting(data: string | undefined | null, prefixString: string = "", suffixString: string = "", suffixSpacing = false) {
  if (isNullOrUndefined(data) || (data as string).length === 0) {
    return "";
  }
  return `${validString(prefixString)} ${data}${suffixSpacing ? " " : ""}${validString(suffixString)}`.trim();
}

export function getDuration(startDate: Date, endDate: Date = new Date(), durationUOM = "second") {
  var momentStartDate = moment(startDate);
  var momentEndDate = moment(endDate);
  var duration = moment.duration(momentEndDate.diff(momentStartDate));

  if (!momentStartDate.isValid) {
    console.log({momentStartDate, momentEndDate, duration});
    return null;
  }

  if (isNotNullOrUndefined(durationUOM)) {
    durationUOM = "second";
  }

  if (durationUOM.startsWith("sec")) {
    return duration.asSeconds();
  }

  if (durationUOM.startsWith("milli")) {
    return duration.asMilliseconds();
  }

  if (durationUOM.startsWith("min")) {
    return duration.asMinutes();
  }

  if (durationUOM.startsWith("d")) {
    return duration.asDays();
  }

  return duration.asSeconds();
}

export function durationDisplay(durationInSeconds: number, fullDisplay: boolean = true) {
  if (isValidNumber(durationInSeconds)) {
    setMomentThresholds();

    if (durationInSeconds > 0 && fullDisplay) {
      durationInSeconds = 0 - Number(durationInSeconds);
    }
    return moment.duration(durationInSeconds, "second").humanize(fullDisplay);
  }
  return "";
}

export function speedDisplay(speed: number, fullDisplay = true) {
  return `${speed} ${fullDisplay ? "km/h" : ""}`;
}

export function listFormatDisplay(list: string[], prefix: string = "", suffix: string = "", suffixSpacing: boolean = false) {
  if (isValidArrayWithData(list)) {
    list = list.filter(item => item.length > 0);
    const formatter = new Intl.ListFormat("en", {
      style: "long",
      type: "conjunction"
    });

    const formattedList = formatter.format(list);

    return `${prefix} ${formattedList}${suffixSpacing ? " " : ""}${suffix}`.trim();
  }
  return "";
}

export function pluralOf(itemName: string, numberOfItems: number, excludeIfZeroOrUndefined = false) {
  if (numberOfItems === undefined && !excludeIfZeroOrUndefined) {
    return pluralize(itemName, 0);
  }

  if (isValidNumber(numberOfItems)) {
    if (excludeIfZeroOrUndefined && Number(numberOfItems) === 0) {
      return "";
    }
    return pluralize(itemName, numberOfItems, true);
  }
  if (excludeIfZeroOrUndefined) {
    return "";
  }

  return pluralize(itemName, numberOfItems);
}

export function singularOf(itemName: string): string {
  return pluralize.singular(itemName);
}

export function commaSeparatedFormatDisplay(commaSeparatedString: string | null | undefined, suffix: string = "", prefix: string = "", suffixSpacing = false) {
  if (isNullOrUndefined(commaSeparatedString) || (commaSeparatedString as string).length === 0) {
    return "";
  }

  return listFormatDisplay((commaSeparatedString as string).split(","), suffix, prefix, suffixSpacing);
}

export function removePrePostQuotes(quotedString: string) {
  const typeOfData = typeof quotedString;
  let unquotedString = quotedString;

  if (isNullOrUndefined(quotedString)) {
    return quotedString;
  }
  if (typeOfData !== "string") {
    console.log("Not String", typeOfData, quotedString);
    return quotedString;
  }

  quotedString = quotedString as string;

  if (quotedString.startsWith("`") && quotedString.endsWith("`")) {
    unquotedString = quotedString.substring(1, quotedString.length - 2);
    console.log({unquotedString, quotedString});
  }
  console.log(451, quotedString);
  return unquotedString;
}

export function distanceDisplay(distanceInMeters: number) {
  if (distanceInMeters >= 1000) {
    const distanceInKm = distanceInMeters / 1000;
    return distanceInKm.toFixed(2) + " km";
  } else {
    return distanceInMeters + " m";
  }
}

export function dateTimeMidnight(date: Date) {
  return moment(date).isValid() ? moment(date).startOf("day").toDate() : date;
}

export function dateTimeOrMinimumDisplay(date: Date) {
  if (new Date(date).toISOString() === "0001-01-01T00:00:00.000Z") {
    return "[NONE]";
  }

  return dateTimeDisplay(date);
}

export function dateTimeDisplay(date: Date, showInFull: boolean = false) {
  if (!moment(date).isValid()) {
    return "";
  } else if (showInFull) {
    return moment(date).format("DD MMM YYYY @ HH:mm:ss");
  }

  return moment(date).format("DD MMM YYYY @ HH:mm");
}

export function dateDisplay(date: Date) {
  return moment(date).isValid() ? moment(date).format("DD MMM YYYY") : "";
}

export function dateMonthYearDisplay(date: Date) {
  return moment(date).format("MMM 'YY");
}

export function dateDayMontDisplay(date: Date) {
  return moment(date).format("DD MMM");
}

export function daysToSameDateLastMonth() {
  const today = new Date();
  const lastMonth = new Date(today);

  lastMonth.setMonth(today.getMonth() - 1);

  const timeDifference = today.getTime() - lastMonth.getTime();
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  return Math.round(daysDifference);
}

export function serviceBackgroundStageColor(stage: string): string {
  switch (stage) {
    case "Un-allocated":
      return "bg-gray-300";
    case "Allocated":
      return "bg-gray-600";
    case "Awaiting Client Feedback":
      return "bg-red-400";
    case "In Progress":
      return "bg-blue-400";
    case "Awaiting Quotation":
      return "bg-yellow-400";
    case "Awaiting Payment":
      return "bg-yellow-400";
    case "Ticket Closed":
      return "bg-green-400";
    case "Unable To Close":
      return "bg-red-600";

    default:
      return "bg-orange-400";
  }
}

export function setLowerCase(data: string | null | undefined) {
  if (isString(data)) {
    return data.toLowerCase();
  }

  return data;
}

// LODASH:  currently loading lodash locally in two functions, if needs be, this can be made global,
export function uniqueArray<T>(arrayData: T[]) {
  var _ = require("lodash");
  return uniq(arrayData);
}

export function nearestInclusiveMaxArray(arrayData: number[], maxValue: number) {
  if (!isValidArrayWithData(arrayData) || !isValidNumber(maxValue)) {
    return undefined;
  }

  let firstMax = maxValue - 1;
  const arrayMax = takeWhile<number>(arrayData, option => {
    const shouldTake = maxValue >= option || firstMax <= maxValue;
    if (firstMax <= option) {
      firstMax = option + 1;
    }

    return shouldTake;
  });

  return arrayMax;
}

export function getNameFromUrlPath(urlPath: string) {
  if (isNullOrUndefined(urlPath)) {
    return "";
  } else if (urlPath === "/") {
    return "Dashboard";
  }

  const splitPath = urlPath.split("/");
  const splitName = splitPath[0] ? splitPath[0] : splitPath[1];

  if (splitPath.length > 2) {
    return `${titleCase(singularOf(splitName))} [${splitPath[2]}]`;
  }

  return titleCase(splitName);
}

export function objectPick<T>(objectValue: T, keysToPick: string[]): Partial<T> | (T & null) {
  if (isNullOrUndefined(objectValue) || !isValidArrayWithData(keysToPick)) {
    return objectValue;
  }

  if (typeof objectValue !== "object") {
    return objectValue;
  }

  return pick<T>(objectValue, keysToPick);
}

export function arrayObjectsPick<T>(arrayData: T[], keysToPick: string[]): Partial<T>[] | null {
  return arrayData.map(objectValue => {
    return objectPick(objectValue, keysToPick) as Partial<T>;
  });
}

export function removeDuplicates<T>(arr: T[]): T[] {
  const uniqueArr: T[] = [];
  for (const item of arr) {
    if (!uniqueArr.includes(item)) {
      uniqueArr.push(item);
    }
  }
  return uniqueArr;
}

export function getDeviceStatusColourScheme() {
  return {
    Offline: "#ff0000",
    Inactive: "#808080",
    Online: "#008000",
    Unknown: "#FF00FF"
  };
}

export function hasExpired(targetDateTime: string | Date) {
  const targetDate = new Date(targetDateTime);

  // Get the current time
  const currentDate = new Date();

  // Compare currentDate with targetDate
  return currentDate.getTime() >= targetDate.getTime();
}

export function getDateYearsFromNow(years: number): Date {
  let date = new Date();
  date.setFullYear(date.getFullYear() + years);
  return date;
}

export function dateTimeCategoryTextColour(category: string): string {
  let textAttrib = "text-brand";

  switch (category) {
    case "24 Hours":
      textAttrib = "text-periodIn24Hours";
      break;
    case "48 Hours":
      textAttrib = "text-periodIn48Hours";
      break;

    case "7 Days":
      textAttrib = "text-periodIn7Days";
      break;

    case "4 Weeks":
      textAttrib = "text-periodIn4Weeks";
      break;

    case "6 Months":
      textAttrib = "text-periodIn6Months";
      break;

    case "9 Months":
      textAttrib = "text-periodIn9Months";
      break;

    case "12 Months":
      textAttrib = "text-periodIn12Months";
      break;

    case "12 Months+":
      textAttrib = "text-periodOver12Months";
      break;

    case "Over 30 Days":
      textAttrib = "text-warn";
      break;

    case "Never":
      textAttrib = "text-periodInfinitely";
      break;

    default:
      textAttrib = "text-secondary";
      break;
  }

  return textAttrib;
}

export function displayFromDateTimeCategoryToDateTime(dateTimeCategory: DateTimeToHourDayCategoryType): string {
  if (dateTimeCategory?.dateTime) {
    return dateTimeDisplay(dateTimeCategory.dateTime) || dateTimeCategory.categoryInfo?.name || "";
  }
  return dateTimeCategory?.categoryInfo?.label || "";
}

export function defaultBrowserTheme() : SystemTheme {

  if (window.matchMedia("prefers-color-scheme:dark").matches) {
    return  SystemThemeSetting.DarkMode;  
  }
  return SystemThemeSetting.LIghtMode;
}