import csv from "csvtojson";

/*
type ConvertCsvToJsonProps = {
    csvFile: Blob,
    matchHeadingTo?: string|null,
    colParser?: string[]|null,
    headers?: string[]|null,
    noHeaders?: boolean,
    ignoreColumns?: string[]
}
*/

export default async function convertCsvToJson({csvFile, matchHeadingTo = null, colParser = null, headers = null, noHeaders = false, ignoreColumns}) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const firstLine = reader.result.split(/\r?\n/)[0];

      if (matchHeadingTo) {
        if (matchHeadingTo !== firstLine) {
          reject({errorType: "File Integrity", error: "CSV File Integrity failed on header"});
        }
      }

      if (colParser && headers) {
        const csvConverter = csv({
          noheader: noHeaders,
          colParser,
          ignoreColumns,
          headers,
          checkType: true,
          checkColumns: true
        });

        csvConverter
          .fromString(reader.result)
          .then(jsonData => {
            resolve(jsonData);
          })
          .catch(error => {
            console.error({error});
            reject({errorType: "file Content", error});
          });
      } else {
        csv()
          .fromString(reader.result)
          .then(jsonData => {
            resolve(jsonData);
          })
          .catch(error => {
            reject(error);
          });
      }
    };
    reader.onerror = error => {
      reject(error);
    };
    reader.readAsText(csvFile);
  });
}
