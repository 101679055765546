import {api} from "./api";

export const usersApi = api.injectEndpoints({
  endpoints: builder => ({
    disableUserRemoteLog: builder.mutation({
      query: ({emailAddress, entityId}) => ({
        url: "/users/disableRemoteLog",
        method: "POST",
        body: {emailAddress, entityId}
      })
    }),
    enableUserRemoteLog: builder.mutation({
      query: ({emailAddress, entityId}) => ({
        url: "/users/enableRemoteLog",
        method: "POST",
        body: {emailAddress, entityId}
      })
    }),
    getAllUsers: builder.query({
      query: () => "/users",
      keepUnusedDataFor: 5
    }),
    getUser: builder.query({
      query: id => `/users/${id}`
    }),
    addUser: builder.mutation({
      query: user => ({
        url: "/users",
        method: "POST",
        body: {...user}
      })
    })
  })
});

export const {useGetAllUsersQuery, useGetUserQuery, useAddUserMutation, useDisableUserRemoteLogMutation, useEnableUserRemoteLogMutation} = usersApi;
