import React from "react";
type Props = { classes: string }


const IgnitionTimeIcon = ({classes }:Props) => {
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M17.618 5.968l1.453-1.453 1.414 1.414-1.453 1.453a9 9 0 1 1-1.414-1.414zM12 20a7 7 0 1 0 0-14 7 7 0 0 0 0 14zM11 8h2v6h-2V8zM8 1h8v2H8V1z" />
    </svg>
  );
};

export default IgnitionTimeIcon;
