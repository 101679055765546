
import React, { useState, useEffect, ReactNode } from 'react';
import { cn } from '../core/utilities';
import { TriangleAlert, Info } from 'lucide-react';


interface TooltipProps {
  message: string;
  children: ReactNode;
  enabled: boolean;
  position?: 'top' | 'bottom' | 'left' | 'right';
  icon: 'warning' | 'info';
}

const Tooltip: React.FC<TooltipProps> = ({
  message,
  children,
  enabled,
  position = 'top',
  icon = 'warning',
}) => {
  const [visible, setVisible] = useState(false);
  let timeout: string | number | NodeJS.Timeout | undefined;

  const delay = 200; // delay in ms to show tooltip
  const showTooltip = () => {
    timeout = setTimeout(() => setVisible(true), delay);
  };

  const hideTooltip = () => {
    clearTimeout(timeout);
    setVisible(false);
  };

  useEffect(() => {
    return () => clearTimeout(timeout);
  }, []);

  const icons: { [key in TooltipProps['icon']]: ReactNode } = {
    warning: <TriangleAlert className='animate-wiggle' />,
    info: <Info className='animate-wiggle' />,
  }

  const positionStyles = {
    top: 'bottom-full left-1/2 transform -translate-x-1/2 mb-2',
    bottom: 'top-full left-1/2 transform -translate-x-1/2 mt-2',
    left: 'right-full top-1/2 transform -translate-y-1/2 mr-2',
    right: 'left-full top-1/2 transform -translate-y-1/2 ml-2',
  }

  return (
    <div
      className="relative flex items-center"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      onFocus={showTooltip}
      onBlur={hideTooltip}
      tabIndex={0} // allows focus for accessibility
    >
      <div className='z-40'>{children}</div>
      {enabled && visible && (
        <div
          className={cn("z-50  absolute px-2.5 py-2 text-xs text-other bg-primary rounded shadow-xl duration-300 w-max ", positionStyles[position])}
          role="tooltip"
          aria-hidden={!visible}
        >
          <div className='z-50 flex flex-row items-center gap-1.5'>
            {icons[icon]}
            <span>{message}</span>
          </div>
          <div
            className={`absolute w-3 h-3 bg-primary transform rotate-45 z-40 ${{
              top: '-bottom-1',
              bottom: '-top-1',
              left: '-right-1',
              right: '-left-1',
            }[position]}`}
          ></div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;

