
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../UI/card"
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent} from "../UI/chart"
import { cn } from "../../core/utilities"
import { Bar as BarChart2, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from "recharts"

import { useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar,getElementAtEvent } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);




export const BarGraph = ({ title, onItemClick , graphData = {}, backgroundColor = [] }) => {
  const chartRef = useRef();
  
  const handleOnClick = (event) => {

    if (onItemClick) {
      const element = getElementAtEvent(chartRef.current, event);
      if (element && element.length > 0) {
        const parsed = element[0].element.$context.element.$context.parsed;
        const { x: row, y: value } = parsed;
        onItemClick(row, value);
      }
      
      
    }
   
  }
  
  const {labels =[], values =[]} = graphData;
 
  const data = {
        labels ,
        datasets: [{
          label: title,
          data: values,
          backgroundColor,
        //   borderColor: [
        //     'rgb(255, 99, 132)',
        //   ],
          borderWidth: 1
        }]
      };

  const config = {
          responsive: true,
          plugins: {
            legend: {
              display: false,
              position: "top"
            },
            title: {
              display: true,
              text: title,
              position: "left"
            }

        },
        borderRadius: 8,
        scales: {
            x: {
              grid: {
                display: false
              }
            },
            y: {
              grid: {
                display: false
              }
            }
          }
      };
  return ( 
        <div className='mx-auto'>
            <Bar data={data} options={config} onClick={handleOnClick} ref={chartRef}/>
        </div>
     );
}
 



export const BarGraphNew = ({data, title, subtitle, xDataKey, yDataKey,barColor,className}) =>{
  const chartConfig = {
      [xDataKey]: {
        label: xDataKey,
        color: "#2563eb",
      }
    } 
return (
  <Card >
    <CardHeader>
      <CardTitle>{title}</CardTitle>
      <CardDescription>{subtitle}</CardDescription>
    </CardHeader>
    <CardContent className={"className"}>
      
          <ChartContainer config={chartConfig} className="w-full ">
            <ResponsiveContainer width="100%" height={300}>
            <BarChart
              accessibilityLayer
              data={data}
             
            >
              <CartesianGrid vertical={false} opacity={0.2} />
              <YAxis
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                tick={{ fill:'var(--color-primary)' }} 
                allowDataOverflow={true}
                domain={[0, 'dataMax']}
                tickFormatter={(value) => value?.toLocaleString()}
              />
          <XAxis
                dataKey={xDataKey}
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                tick={{ fill:'var(--color-primary)' }}
              /> 
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent indicator="line" labelKey={yDataKey} nameKey={xDataKey}  />}
              />
            <BarChart2
                dataKey={yDataKey}
                radius={8}
                type="natural"
                fill={barColor}
            
              />
            
          
            </BarChart>
              
              </ResponsiveContainer>
            
          </ChartContainer>
    
        
    </CardContent>
  </Card>
)
}

