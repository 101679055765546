import React from "react";
import DataCardSection, { ILabelValuePairProps } from "../../DataCardSection";

interface ISimCardDataCardSectionProps {
    active: boolean;
    id: string;
    apn: string;
    iccid: string;
    imsi: string;
    msisdn: string; 
  isSimCardInstalled: boolean;
    enableSimLink?: boolean;
}

function SimCardDataCardSection({isSimCardInstalled, active, apn, iccid,imsi, msisdn, id, enableSimLink = false }: ISimCardDataCardSectionProps) {
  const title = "SIM Card Information";
  const sectionData: ILabelValuePairProps[] = [
    {
      label: "Active",
      value: active
    },
    {
      label: "Apn",
      value: apn,
      copyable: false
    },
    {
      label: "MSISDN",
      value: msisdn,
      copyable: true
    },
    {
      label: "ICCID",
      value: iccid,
      copyable: true
    },
    {
      label: "IMSI",
      value: imsi,
      copyable: true
}
  ];

  if (apn === "onomondo") {
    sectionData.splice(1,0,{
      label: "Id",
      value: id,
      copyable: true
    });
    }
    
    if (enableSimLink) {
      const index = sectionData.findIndex((data) => data.label.toLowerCase() === "iccid");
     // console.log({index,sectionData});
        if (index !== -1) {
            sectionData[index] = {
                ...sectionData[index],
                link: {
                    to: `/sim-cards/${iccid}`,
                    title: "View SIM Details"
                }
            };
        }
         
  }
  
  if (apn === "onomondo") { 
    

    const url = `https://app.onomondo.com/sims/${id}`;

    const index = sectionData.findIndex((data) => data.label.toLowerCase() === "id");
    // console.log({index,sectionData});
       if (index !== -1) {
           sectionData[index] = {
               ...sectionData[index],
               href: {
                   url,
                   title: "View SIM Details on Onomondo",
               }
           };
       }
    
  }
    const warningMessage = isSimCardInstalled ? "" : "No SIM Card Installed";

  return (
    <div className="flex">
      <DataCardSection title={title} data={sectionData} warning={warningMessage } />
    </div>
  );
}

export default SimCardDataCardSection;
