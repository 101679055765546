import { CartesianGrid, LabelList, Line, LineChart, XAxis,Bar, BarChart,YAxis } from "recharts"

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../UI/card"
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent} from "../UI/chart"
import { cn } from "../../core/utilities"




// const LineGraph = ({ title, borderColor, graphData = {} }) => {




const LineGraph = ({data, title, subtitle, xDataKey, yDataKey,lineColor, children,className}) =>{
    const chartConfig = {
        [xDataKey]: {
          label: xDataKey,
          color: "#2563eb",
        }
      } 
  return (
    <Card >
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{subtitle}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className={cn("flex flex-col w-full", className)}>
        
            <ChartContainer config={chartConfig} className="min-h-[200px] w-full">
              <LineChart
                accessibilityLayer
                data={data}
                margin={{
                  top: 20,
                  left: 0,
                  right: 12,
                }}
              >
                <CartesianGrid vertical={false} opacity={0.2} />
                <YAxis
                  tickLine={false}
                  axisLine={false}
                  tickMargin={8}
                  tick={{ fill:'var(--color-primary)' }} 
                />
            <XAxis
                  dataKey={xDataKey}
                  tickLine={false}
                  axisLine={false}
                  tickMargin={8}
                  tick={{ fill:'var(--color-primary)' }}
                /> 
                <ChartTooltip
                  cursor={false}
                  content={<ChartTooltipContent indicator="line" labelKey={yDataKey} nameKey={xDataKey}  />}
                />
              <Line
                  dataKey={yDataKey}
                  type="natural"
                  stroke={lineColor}
                  strokeWidth={2}
                  dot={{fill: lineColor}}
                  activeDot={{r: 6}}
                >
                  {/* <LabelList
                    position="top"
                    offset={12}
                    className="fill-primary"
                    fontSize={12}
                  /> */}
                </Line>
              </LineChart>
            </ChartContainer>
      

        {children}

          
        </div>
      </CardContent>
    </Card>
  )
}

export default LineGraph