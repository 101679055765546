import {createSlice} from "@reduxjs/toolkit";
import {SystemThemeSetting} from "../../models/other.model";
import {SettingsChart, SettingsDashboard, SettingsDataDisplay, SettingsFilter, SettingsPagination, SettingsTableColumnOptions, UserSettings} from "../../models/user.settings.model";
import {RootState} from "../../store/store";

const initialState: UserSettings = {
  theme: "light-theme",
  previousPage: {previous: "", current: ""},
  sidebar: {expanded: true},
  dataDisplay: [],
  dashboard: [],
  filters: [],
  charts: [],
  pagination: [],
  tables: {columnOptions: []},
  features: []
};

function findExistingDataDisplay(dataDisplays: SettingsDataDisplay[], dataDisplayName: string) {
  return dataDisplays.find(dataDisplay => dataDisplay.name === dataDisplayName);
}

function findExistingPagination(paginations: SettingsPagination[], paginationName: string) {
  return paginations.find(pagination => pagination.name === paginationName);
}

function findExistingFilter(filters: SettingsFilter[], filterName: string) {
  return filters.find(filter => filter.name === filterName);
}

function findExistingChart(charts: SettingsChart[], chartName: string) {
  return charts.find(chart => chart.name === chartName);
}

function findExistingTableHidden(columnOptions: SettingsTableColumnOptions[], tableName: string) {
  const columnOption = columnOptions ? columnOptions.find(table => table.name === tableName) : undefined;

  return columnOption ? columnOption.hidden : undefined;
}

function findExistingDashboardFeature(dashboard: SettingsDashboard[], name: string) {
  return dashboard.find(feature => feature.name === name);
}

export const settingsSlice = createSlice({
  name: "settings",
  initialState: initialState,
  reducers: {
    setPreviousPage: (state, action) => {
      console.log({action});
      state.previousPage = action.payload;
    },
    setDarkTheme: state => {
      state.theme = SystemThemeSetting.DarkMode;
    },
    setLightTheme: state => {
      state.theme = SystemThemeSetting.LIghtMode;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    toggleTheme: state => {
      state.theme = state.theme === SystemThemeSetting.LIghtMode ? SystemThemeSetting.DarkMode : SystemThemeSetting.LIghtMode;
    },
    expandSidebar: state => {
      state.sidebar = {expanded: true};
    },
    minimizeSidebar: state => {
      state.sidebar = {expanded: false};
    },
    setFilterOptionsFeature: (state, action) => {
      const {filterOptions} = action.payload;
      if (findExistingFilter(state.filters, action.payload.name)) {
        state.filters = state.filters.map(feature => {
          if (feature.name === action.payload.name) {
            feature.filterOptions = filterOptions;
          }
          return feature;
        });
      } else {
        state.filters.push({name: action.payload.name, filterOptions, isFilter: true});
      }
    },
    setPerPageFeature: (state, action) => {
      const {perPage} = action.payload;
      if (findExistingPagination(state.pagination, action.payload.name)) {
        state.pagination = state.pagination.map(pagination => {
          if (pagination.name === action.payload.name) {
            pagination.perPage = perPage;
          }
          return pagination;
        });
      } else {
        state.pagination.push({name: action.payload.name, perPage});
      }
    },
    setIsFilterFeature: (state, action) => {
      const {isFilter} = action.payload;
      if (findExistingFilter(state.filters, action.payload.name)) {
        state.filters = state.filters.map(filter => {
          if (filter.name === action.payload.name) {
            filter.isFilter = isFilter;
          }
          return filter;
        });
      } else {
        state.filters.push({name: action.payload.name, isFilter, filterOptions: []});
      }
    },
    setIsShowChartsFeature: (state, action) => {
      const {isShowCharts, name} = action.payload;
      if (findExistingChart(state.charts, name)) {
        state.charts = state.charts.map(chart => {
          if (chart.name === action.payload.name) {
            chart.visible = isShowCharts;
          }
          return chart;
        });
      } else {
        state.charts.push({name: action.payload.name, visible: isShowCharts});
      }
    },
    setIsDisplayLiveOnlyFeature: (state, action) => {
      const {isDisplayLiveOnly, name} = action.payload;
      if (findExistingDataDisplay(state.dataDisplay, name)) {
        state.dataDisplay = state.dataDisplay.map(feature => {
          if (feature.name === name) {
            feature.displayLiveOnly = isDisplayLiveOnly;
          }
          return feature;
        });
      } else {
        state.dataDisplay.push({name, displayLiveOnly: isDisplayLiveOnly});
      }
    },
    setTableHiddenOptionFeature: (state, action) => {
      const {hidden, name} = action.payload;
      if (findExistingTableHidden(state.tables.columnOptions, name)) {
        state.tables.columnOptions = state.tables.columnOptions.map(feature => {
          if (feature.name === action.payload.name) {
            feature.hidden = hidden;
          }
          return feature;
        });
      } else {
        if (!state.tables) {
          console.log({state, action});
          state.tables = {columnOptions: []};
        }
        state.tables.columnOptions.push({name, hidden});
      }

      //console.log({tables:state.tables, action });
    }
  }
});

export const {setLightTheme, setDarkTheme, toggleTheme, setPreviousPage, setTheme, expandSidebar, minimizeSidebar, setFilterOptionsFeature, setPerPageFeature, setIsFilterFeature, setIsShowChartsFeature, setIsDisplayLiveOnlyFeature, setTableHiddenOptionFeature} = settingsSlice.actions;

export const selectPreviousPage = (state: RootState) => state?.settings.previousPage;
export const selectCurrentTheme = (state: RootState) => state.settings.theme;
export const selectCurrentSidebar = (state: RootState) => state.settings.sidebar;
export const getFilterOptionsFeature = (name: string) => (state: RootState) => findExistingFilter(state.settings.filters, name)?.filterOptions;
export const getPerPageFeature = (name: string) => (state: RootState) => findExistingPagination(state.settings.pagination, name)?.perPage || 10;
export const getIsFilterFeature = (name: string) => (state: RootState) => findExistingFilter(state.settings.filters, name)?.isFilter || false;
export const getIsShowChartsFeature = (name: string) => (state: RootState) => findExistingChart(state.settings.charts, name)?.visible || false;
export const getIsDisplayLiveOnlyFeature = (name: string) => (state: RootState) => findExistingDataDisplay(state.settings.dataDisplay, name)?.displayLiveOnly || false;
export const getTableHiddenOptionFeature = (name: string) => (state: RootState) => findExistingTableHidden(state.settings.tables.columnOptions, name);

export default settingsSlice.reducer;
