import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Api from "../../api";
import { useDisableUserRemoteLogMutation, useEnableUserRemoteLogMutation } from "../../api/rtk/usersApi";
import { Layout, NoRecords, OnError, PageFilter, Pagination, PrimaryHeader } from "../../components";
import Filter from "../../components/filter/Filter";
import FilterButton from "../../components/filter/FilterButton";
import ProcessFlowDialog, { UserProcessFlowInit } from "../../components/flow/ProcessFlowDialog";
import { SystemTables, Table,initializeTableColumns } from "../../components/table";
import { ProcessFlowStage, UserRoleAccess } from "../../constant";
import { isValidArrayWithData } from "../../core/utilities";
import FilterOptions from "../../filter/FilterOptions";
import useHasAccessToFeature from "../../hooks/useHasAccessToFeature";
import usePageFilter from "../../hooks/usePageFilter";
import TablesSkeleton from "../../skeletons/TablesSkeleton";
import { getTableHiddenOptionFeature, setTableHiddenOptionFeature } from "../../features/settings/settingsSlice";

export default function Users() {
  const { data: users = undefined, isLoading, isError, error, refetch: refetchUsers } = Api.users.useGetAllUsersQuery();
  const usersFound =  isValidArrayWithData(users);

  console.log("Users start...",{isLoading,isError})
  // console.log({ users });

  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (
    <TablesSkeleton isLoading={isLoading} title={"Users"} />
  ) : usersFound ? (
    <DisplayUsers users={users} refetchUsers={refetchUsers} />
  ) : (
    <NoRecords title="Users" message="No Users found." />
  );
}

function DisplayUsers({ users, refetchUsers }) {
  const [tableInfo] = useState(SystemTables.users);
  const currentHiddenColumns = useSelector(getTableHiddenOptionFeature(tableInfo.name));
  const [displayData, setDisplayData] = useState(users);
  const [isFilter, setIsFilter] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [tableColumns, setTableColumns] = useState(initializeTableColumns(tableInfo.columnOptions,currentHiddenColumns));
  const [userProcessFlow, setUserProcessFlow] = useState(UserProcessFlowInit);
  const { numberOfItemsOnCurrentPage, itemsPerPage, currentPage, numberOfPages, totalNumberOfItems, handleItemsPerPageChange, handlePageClick } = usePageFilter({ displayData });
  const navigate = useNavigate();
  const { hasAccess: hasAccessToViewUsers } = useHasAccessToFeature(UserRoleAccess.userView);
  const { hasAccess: hasAccessToChangeRemoteLog } = useHasAccessToFeature(UserRoleAccess.userManageRemoteLog);
  const [enableUserRemoteLog] = useEnableUserRemoteLogMutation();
  const [disableUserRemoteLog] = useDisableUserRemoteLogMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    setDisplayData(users);
  }, [users]);

  const handleFilterDataChange = (filteredData) => {
    setIsFiltered(filteredData.length !== users.length);
    setDisplayData(filteredData);
  };

  const handleTableDoubleClick = (_, identifier) => {
    if (hasAccessToViewUsers) {
      navigate(`/users/${identifier}`);
    }
  };

  const onColumnOptionsChange = (columnOptions) => {
    setTableColumns(columnOptions);
    const hidden = columnOptions.filter(column => column.isHidden).map(column => column.key);
    dispatch(setTableHiddenOptionFeature({ name: tableInfo.name, hidden }));
  };

  const filterOptions = () => {
    const option = new FilterOptions();
    return option.getUsers();
  };

  const onUserProcessConfirm = async ({ confirm, stage, processId }) => {
    setUserProcessFlow({ ...userProcessFlow, stage });

    if (confirm.yes) {
      const { key, type, user } = userProcessFlow.processData;

      if (key === "enable_remote_log") {
        const processMessage = "Processing User Remote Logging";
        setUserProcessFlow({ stage: ProcessFlowStage.Processing, processMessage });
        const response = await enableDisableUserRemoteLog(user.email_address, user.entity_id, type === "Enable");

        if (response.success) {
          setUserProcessFlow({ stage: ProcessFlowStage.Info, infoMessage: `User Remote Logging ${type} successfully` });
        } else {
          setUserProcessFlow({ stage: ProcessFlowStage.Alert, alertMessage: `User Remote Logging ${type} could not be completed successfully` });
        }
      }
    }
  };

  const handleTableEditableBooleanAccessCheck = ({ key }) => {
    if (key === "enable_remote_log") {
      return hasAccessToChangeRemoteLog;
    }
    return false;
  };
  const handleTableEditableBooleanChange = ({ id, key, value }) => {
    if (key === "enable_remote_log") {
      const user = users.find((user) => user.id === id);
      const type = value ? "Disable" : "Enable";
      //const message = `Are you sure you want to ${type} Remote Logging for ${user.fullName}?`;
      // setCommandConfirmation({ confirm: true, message,key,type, user });

      const confirmMessage = `Are you sure you want to ${type} Remote Logging for ${user.fullName}?`;
      setUserProcessFlow({ stage: ProcessFlowStage.Confirm, confirmMessage, processData: { key, type, user }, processId: key });
    }
  };

  const enableDisableUserRemoteLog = async (emailAddress, entityId, enable) => {
    return new Promise((resolve) => {
      if (enable) {
        enableUserRemoteLog({ emailAddress, entityId })
          .unwrap()
          .then((response) => {
            refetchUsers();
            resolve({ success: response.success });
          })
          .catch((error) => {
            console.error(error);
            resolve({ success: false });
          });
      } else {
        disableUserRemoteLog({ emailAddress, entityId })
          .unwrap()
          .then((response) => {
            refetchUsers();
            resolve({ success: response.success });
          })
          .catch((error) => {
            console.log({ error });
            resolve({ success: false });
          });
      }
    });
  };

  // console.log({currentHiddenColumns,tableColumns});
  return (
    <Layout>
      <PrimaryHeader title="Users">
        <div className="flex">
          <FilterButton isFilter={isFilter} setIsFilter={setIsFilter} />
        </div>
      </PrimaryHeader>
      <ProcessFlowDialog
        processId={userProcessFlow.processId}
        processStage={userProcessFlow.stage}
        alertMessage={userProcessFlow.alertMessage}
        confirmMessage={userProcessFlow.confirmMessage}
        infoMessage={userProcessFlow.infoMessage}
        processingMessage={userProcessFlow.processMessage}
        onConfirm={onUserProcessConfirm}
      />
      <Filter enabled={isFilter} data={users} filterOptions={filterOptions()} handleFilterDataChange={handleFilterDataChange} />
      <section className="detail-section">
        <PageFilter
          itemsPerPage={itemsPerPage}
          setItemsPerPage={handleItemsPerPageChange}
          numberOfItemsOnCurrentPage={numberOfItemsOnCurrentPage}
          recordsName={"Users"}
          isFiltered={isFiltered}
          totalNumberOfItems={totalNumberOfItems}
        />
        <Table
          data={displayData}
          pagingInfo={{ dataPerPage: itemsPerPage, currentPage }}
          tableColumns={tableColumns}
          initialSort={{ key: "email_address", order: "asc" }}
          onTableDataDoubleClick={handleTableDoubleClick}
          includeSequentialId={true}
          onEditableBooleanChange={handleTableEditableBooleanChange}
          onEditableBooleanAccessCheck={handleTableEditableBooleanAccessCheck}
          showTableColumnSelect={true}
          tableColumnSelectOptions={tableColumns}
          onColumnOptionsChange={onColumnOptionsChange}
        />

        <Pagination handlePageClick={handlePageClick} numberOfPages={numberOfPages} />
      </section>
    </Layout>
  );
}
