import React, { useState } from 'react';

const ToggleButton = ({ isChecked, onToggle }) => {
    return (
        <div className="flex items-center gap-2">
            <div
                className={`relative w-[4.1rem] h-8 rounded-full flex items-center px-[3px] transition-colors duration-300 ${isChecked ? 'bg-brand' : 'bg-secondary'
                    }`}
            >
                <button
                    type='button'
                    onClick={onToggle}
                    className={`absolute top-0.5 h-7 w-7 rounded-full bg-white transition-transform duration-300 ${isChecked ? 'translate-x-8' : 'translate-x-0.2'
                        }`}
                />
                <div className="flex justify-between w-full px-1 text-xs font-medium text-white">
                    <span className={`flex-1 text-center ${isChecked ? 'text-white' : 'text-black'}`}>Yes</span>
                    <span className={`flex-1 text-center ${isChecked ? 'text-black' : 'text-white'}`}>No</span>
                </div>
            </div>
        </div>
    );
};

export default ToggleButton;