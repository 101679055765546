import React, { Fragment } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function LiveMovementSkeleton() {
  return (
    <Fragment>
      <SkeletonTheme baseColor="var(--color-skeleton)" highlightColor="var(--color-background)">
        <section className="section-container">
          <h2>
            <Skeleton width={80} height={30} />{" "}
            <span className="px-3 py-1 text-sm text-other rounded-xl">
              <Skeleton width={80} height={30} />
            </span>
          </h2>

          <div className="grid grid-cols-1 mt-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <div className="speedometer-container">
              <h3>
                <Skeleton width={80} height={30} />
              </h3>
              <Skeleton width={80} height={30} />
            </div>

            <div className="speedometer-container">
              <h3>
                <Skeleton width={80} height={30} />
              </h3>
              <Skeleton width={80} height={30} />
            </div>

            <div className="speedometer-container">
              <h3>
                <Skeleton width={80} height={30} />
              </h3>
              <Skeleton width={80} height={30} />
            </div>

            <div className="speedometer-container">
              <h3>
                <Skeleton width={80} height={30} />
              </h3>
              <Skeleton width={80} height={30} />
            </div>
          </div>
        </section>

        <section className="section-container">
          <h2>Today</h2>
          <div className="flex flex-row gap-16">
            <div className="flex flex-row gap-2">
              <p className="inline-flex flex-col">
                <Skeleton width={80} height={30} />
                <span >
                  <Skeleton width={80} height={30} />
                </span>
              </p>
            </div>
            <div className="flex flex-row gap-2">
              <p className="inline-flex flex-col">
                <Skeleton width={80} height={30} />{" "}
                <span>
                  <Skeleton width={80} height={30} />
                </span>
              </p>
            </div>
            <div className="flex flex-row gap-2">
              <p className="inline-flex flex-col">
                <Skeleton width={80} height={30} />{" "}
                <span >
                  <Skeleton width={80} height={30} />
                </span>
              </p>
            </div>
          </div>
        </section>

        <section className="section-container h-88">
          <div className="inline-flex items-center gap-4">
            <h2 className="mb-2 ">Vehicle Live Locations</h2>
            <span className="inline-flex items-center gap-1 p-1 border rounded-full border-primary bg-other animate-pulse">
              <span className="w-3 h-3 rounded-full bg-primary"></span>
            </span>
          </div>
          <div className="w-full h-88">
            <Skeleton width={180} height={230} />
          </div>
        </section>
      </SkeletonTheme>
    </Fragment>
  );
}
