import React from "react";
import { DashCamFeedIcon, FuelConsumedIcon, LiveDataIcon, VehicleHealthIcon } from "../icons";

const DataCard = ({ name, value, icon, hasLink = false }) => {
  const IconStyle = `fill-current h-12 w-12 ${hasLink ? "group-hover:scale-125 transition-all group-hover:fill-brand" : ""}`;
  return (
    <section className={`group relative gap-0 section-container ${hasLink ? "hover:shadow-xl" : ""}`}>
      <div className="flex flex-row items-center gap-2 group ">
        {icon === "fuelConsumption" ? (
          <FuelConsumedIcon classes={IconStyle} />
        ) : icon === "vehicleHealth" ? (
          <VehicleHealthIcon classes={IconStyle} />
        ) : icon === "cameraFeed" ? (
          <DashCamFeedIcon classes={IconStyle} />
        ) : icon === "dataFeed" ? (
          <LiveDataIcon classes={IconStyle} />
        ) : (
          ""
        )}

        <h3 className="text-xl font-semibold group-hover:text-brand">{name}</h3>
        <span className="absolute right-0 px-3 py-1 -mt-3 text-xl rounded-l-full top-3 bg-brand text-other ">{value}</span>
      </div>
    </section>
  );
};

export default DataCard;
