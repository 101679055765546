import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { expandSidebar, minimizeSidebar,selectCurrentTheme } from "../features/settings/settingsSlice";

import { defaultBrowserTheme } from "../core/utilities";
import SwitchTheme from "./actions/switchTheme";
import UserMenu from "./header/UserMenu";
import systemConfig from "../config/systemConfiguration";
import ConditionalNavLink from "./ConditionalNavLink";
import getEntityLogo from "../core/entityLogo";


function LayoutHeader({ sidebarExpanded, setSidebarObject }) {
  const user = useSelector((state) => state.auth.user);
  const [theme] = useState(useSelector(selectCurrentTheme)||defaultBrowserTheme());
  const dispatch = useDispatch();
  
  
  useEffect(() => {
    if (sidebarExpanded) {
      dispatch(expandSidebar());
    } else {
      dispatch(minimizeSidebar());
    }
  }, [sidebarExpanded, dispatch]);




  const fleetName =  user.fleetInfo?.name;
  

  return (
    <header className="sticky top-0 z-30 shadow-md bg-other">
      <div className="px-2 sm:px-2 lg:px-2">
        <div className="flex items-center justify-between h-16 -mb-px">
          {/* Header: Left side */}
          <div className="flex flex-row items-center gap-4 ">
            {/* Hamburger button */}
            <button
              className="text-secondary"
              aria-controls="sidebar"
              aria-expanded={sidebarExpanded}
              onClick={(e) => {
                e.stopPropagation();
                setSidebarObject({ expanded: !sidebarExpanded });
              }}
            >
              <span className="sr-only">Open sidebar</span>

              <svg className="w-6 h-6 fill-primary hover:fill-brand" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>

            {/* Logo */}
            <ConditionalNavLink path={"/"} classes="flex flex-row items-center gap-2">
              <img src={getEntityLogo(systemConfig.entity.acronym,theme)} alt="logo" className="w-12 h-12 -mt-2" />
              <h1 className="hidden text-lg font-semibold text-primary opacity-90 sm:block">{`${systemConfig.entity.name} Fleet Management`} </h1>
            </ConditionalNavLink>
          </div>

          <div className="flex items-center">
            <h3 className="text-sm text-secondary"> {fleetName} </h3>
          </div>

          {/* Header: Right side */}
          <div className="flex items-center">
            <UserMenu user={user} />
            <SwitchTheme />
          </div>
        </div>
      </div>
    </header>
  );
}

export default LayoutHeader;
