import systemConfig from "../config/systemConfiguration";

const API_KEY = systemConfig.maps.google.apiKey;

export async function getLocationName(coordinates) {
  return new Promise((resolve, reject) => {
    fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates.latitude},${coordinates.longitude}&key=${API_KEY}`)
      .then(response => {
        response.json().then(data => {
          if (data.status === "OK" && data.results.length > 0) {
            resolve(data.results);
          } else {
            reject("Location details not found");
          }
        });
      })
      .catch(error => {
        reject(error);
      });
  });
}
