import React, { useMemo, useState } from "react";
import Api from "../../api";
import { AlertDialog, Layout, NoRecords, OnError, PrimaryHeader } from "../../components";
import DateRangeSelector from "../../components/date/DateRangePicker"; // Import the NewDatePicker component
import InputSelect from "../../components/InputSelect";
import { dateTimeMidnight, isValidArrayWithData } from "../../core/utilities";
import TablesSkeleton from "../../skeletons/TablesSkeleton";

export default function VehiclesLocationsTrackingReport() {
  const { data: vehicles = [], isLoading, isError, error } = Api.vehicles.useGetAllVehiclesQuery();
  const vehiclesWithLocationTracking = vehicles.length > 0 ? vehicles.filter((vehicle) => vehicle.numberOfTrackingLocations > 0) : null;
  const vehiclesFound = isValidArrayWithData(vehiclesWithLocationTracking);

  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (
    <TablesSkeleton isLoading={isLoading} title="Vehicle Location Tracking Report" />
  ) : vehiclesFound ? (
    <DisplayVehiclesTrackingReportCreate vehiclesWithLocationTracking={vehiclesWithLocationTracking} />
  ) : (
    <NoRecords title="Vehicle Location Tracking Report" message="No Vehicles found, location tracking reports cannot be generated." />
  );
}

function DisplayVehiclesTrackingReportCreate({ vehiclesWithLocationTracking }) {
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null }); // Initial state set to null
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [alertInfo, setAlertInfo] = useState({ show: false });
  const [isLoadingInfo, setIsLoadingInfo] = useState({ status: false });
  const [postLocationTrackingReport] = Api.vehicles.usePostVehiclesLocationTrackingReportMutation();

  const vehicleList = useMemo(() => {
    return vehiclesWithLocationTracking.map((vehicle) => ({ value: vehicle?.id, label: vehicle?.vehicleRegistrationNumber, id: vehicle.id, model: vehicle?.makeAndModel }));
  }, [vehiclesWithLocationTracking]);

  const resetForm = () => {
    setDateRange({ startDate: null, endDate: null });
    setSelectedVehicles([]);
  };

  const handlePdfReport = async (e) => {
    e.preventDefault();
    const vehicles = selectedVehicles.map((vehicle) => ({ id: vehicle.id }));
    const reportInfo = {
      vehicles,
      dateRange: {
        startDate: dateTimeMidnight(dateRange?.startDate),
        endDate: dateTimeMidnight(dateRange?.endDate)
      }
    };

    if (!isValidDateRange(reportInfo.dateRange.startDate, reportInfo.dateRange.endDate)) {
      setAlertInfo({ show: true, message: "Date range cannot be more than 1 year" });
      return;
    }

    try {
      setIsLoadingInfo({ status: true, text: "Processing Vehicle Location Tracking Report" });
      const response = await postLocationTrackingReport(reportInfo).unwrap();
      setIsLoadingInfo({ status: false });

      if (response.success) {
        resetForm();
        setAlertInfo({ show: true, message: "Location Tracking report has been generated and will be emailed to you shortly" });
      } else {
        setAlertInfo({ show: true, message: "Report could not be generated, please try again" });
      }
    } catch (error) {
      setIsLoadingInfo({ status: false });
      setAlertInfo({ show: true, message: "Report could not be generated, please try again" });
      if (error.status === 403) {
        window.location.reload();
      }
    }
  };

  const isValidDateRange = (start, end) => start <= end && end - start <= 365 * 24 * 60 * 60 * 1000;

  const handleSelectVehicle = (data) => {
    setSelectedVehicles(data);
  };

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  return (
    <Layout isLoading={isLoadingInfo.status} loadingText={isLoadingInfo.text}>
      <PrimaryHeader title="Vehicle Location Tracking Report" />
      <AlertDialog show={alertInfo.show} alertMessage={alertInfo.message} onClick={() => setAlertInfo({ show: false })} />

      <section className="flex flex-col items-center justify-center w-full h-full px-10 py-20">
        <form className="flex flex-col w-full gap-4 xl:w-1/2" onSubmit={handlePdfReport}>
          <div className="h-full">
            <span className="text-primary">Select Vehicle</span>
            <InputSelect
              required={true}
              selectionOptions={vehicleList}
              setSelectedOption={handleSelectVehicle}
              allowMultiples={true}
              placeholder={"--Select vehicle(s) --"}
              name={"vehicle_id"}
              className="text-sm font-medium"
              allowSelectAll={true}
              itemName={"Vehicle"}
            />
          </div>
          <div className="w-full">
            <DateRangeSelector onChange={handleDateRangeChange} maxDate={new Date()} />
          </div>
          <div className="flex justify-end">
            <button className="btn-primary" type="submit">
              Generate Report
            </button>
          </div>
        </form>
      </section>
    </Layout>
  );
}
