import {baseRequestConfig} from "../config/requestConfigs";
import {baseApi} from "../rtk/baseApi";

export const authApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation({
      query: credentials => ({
        url: "/authenticate",
        method: "POST",
        body: {...credentials, isDashboardLogin: true}
      })
    }),
    logout: builder.mutation({
      query: credentials => ({
        url: "/authenticate",
        method: "DELETE",
        body: {...credentials}
      })
    }),
    getMicrosoftLoginUrl: builder.query({
      query: callbackUrl => {
        // Parse the URL and modify the callbackUrl
        const parsedUrl = new URL("/authenticate/microsoft/login", baseRequestConfig.baseURL);
        const params = new URLSearchParams(parsedUrl.search);
        params.set("callbackUrl", callbackUrl); // Use the callbackUrl defined in your component
        parsedUrl.search = params.toString();

        // Return the modified URL
        return parsedUrl.toString();
      }
    }),
    getMicrosoftCallback: builder.query({
      query: param => {
        // Parse the URL and modify the callbackUrl
        const parsedUrl = new URL("/authenticate/microsoft/callback", baseRequestConfig.baseURL);
        const params = new URLSearchParams(parsedUrl.search);
        params.set("code", param.code); // Use the code defined in your component
        params.set("callbackUrl", param.callbackUrl); // Use the callbackUrl defined in your component
        parsedUrl.search = params.toString();

        // Return the modified URL
        return parsedUrl.toString();
      }
    })
  })
});

export const {useLoginMutation, useLogoutMutation, useLazyGetMicrosoftLoginUrlQuery, useGetMicrosoftCallbackQuery} = authApi;
