import React, { useEffect, useState, useRef } from "react";
import { FaSortAmountDown } from "react-icons/fa";

const TableColumnSelectableOptions = ({ columnOptions, onColumnOptionsChange }) => {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const optionsRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setOptionsVisible(false);
      }
    };

    if (optionsVisible) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [optionsVisible]);

  const toggleOptions = () => {
    setOptionsVisible(!optionsVisible);
  };

  const handleSelectOption = (option, value) => {
    option.isHidden = value;
    const changedOptions = columnOptions.map((columnOption) => {
      if (columnOption.key === option.key) {
        columnOption.isHidden = value;
      }
      return columnOption;
    });

    if (onColumnOptionsChange) {
      onColumnOptionsChange(changedOptions);
    }
  };

  const handleMouseLeave = () => {
    setOptionsVisible(false);
  };

  return (
    <div className="relative w-full" ref={optionsRef}>
      <button type="button" className="flex items-center justify-center w-8 h-8 mr-2 bg-gray-200 rounded-full focus:outline-none hover:bg-gray-300" onClick={toggleOptions}>
        <FaSortAmountDown />
      </button>
      {optionsVisible && columnOptions && (
        <div className="absolute right-0 mt-2 rounded-md shadow-xl bg-tertiary min-w-64 bg--quaternary text-primary ring-1 ring-black ring-opacity-5" onMouseLeave={handleMouseLeave}>
          {columnOptions
            .filter((option) => option.isShown)
            .map((option) => (
              <label key={option.key} className="flex items-center px-4 py-2 text-sm hover:cursor-pointer">
                <input type="checkbox" className="mr-2 rounded-full" checked={!option.isHidden} onChange={() => handleSelectOption(option, !option.isHidden)} />
                <span>{option.label}</span>
              </label>
            ))}
        </div>
      )}
    </div>
  );
};

export default TableColumnSelectableOptions;
