import systemConfig from "../../config/systemConfiguration";
import Constant from "../../constant";
import {api} from "./api";
const {entity} = systemConfig;

export const hubspotTicketsApi = api.injectEndpoints({
  endpoints: builder => ({
    getAllHubspotTickets: builder.query({
      query: () => "/hubspot/tickets",
      keepUnusedDataFor: 5
    }),
    getAllBreakdownTickets: builder.query({
      query: () => "/hubspot/tickets/breakdowns"
    }),
    getActiveBreakdownTicketsCount: builder.query({
      query: () => "/hubspot/tickets/breakdowns?activeOnly=true&countOnly=true"
    }),
    getAllAccidentsTickets: builder.query({
      query: () => "/hubspot/tickets/accidents"
    }),
    getAllServicesTickets: builder.query({
      query: () => "/hubspot/tickets/services"
    }),
    getAllInspectionsTickets: builder.query({
      query: () => "/hubspot/tickets/inspections"
    }),
    getAllFinesManagementTickets: builder.query({
      query: () => "/hubspot/tickets/fines"
    }),
    getAllTheftTickets: builder.query({
      query: () => "/hubspot/tickets/thefts"
    }),
    getAllExpiredLicenseTickets: builder.query({
      query: () => "/hubspot/tickets/expired"
    }),
    getAllDiagnosticAlertsTickets: builder.query({
      query: () => "/hubspot/tickets/alerts"
    }),
    getAllRoadsideAssistanceTickets: builder.query({
      query: () => "/hubspot/tickets/roadside"
    }),
    getHubspotTicket: builder.query({
      query: id => `/hubspot/tickets/${id}`
    }),
    synchronizeHubSpotTickets: builder.mutation({
      query: () => ({
        url: `/hubspot/tickets/retrieve/tickets?customerUuId=${entity.uuId}&key=${Constant.System.Riiot.Api.Key}`,
        method: "GET"
      })
    })
  })
});

export const {useGetAllHubspotTicketsQuery, useGetAllBreakdownTicketsQuery, useGetActiveBreakdownTicketsCountQuery, useGetAllAccidentsTicketsQuery, useGetAllServicesTicketsQuery, useGetAllInspectionsTicketsQuery, useGetAllFinesManagementTicketsQuery, useGetAllTheftTicketsQuery, useGetAllExpiredLicenseTicketsQuery, useGetHubspotTicketQuery, useGetAllDiagnosticAlertsTicketsQuery, useGetAllRoadsideAssistanceTicketsQuery, useSynchronizeHubSpotTicketsMutation} = hubspotTicketsApi;
