import React, { useMemo, useState, useRef } from "react";
import Api from "../../api";
import DateRangeSelector from "../../components/date/DateRangePicker";
import { AlertDialog, Layout, NoRecords, OnError, PrimaryHeader } from "../../components";
import InputSelect from "../../components/InputSelect";
import { isValidArrayWithData,dateTimeMidnight } from "../../core/utilities";
import TablesSkeleton from "../../skeletons/TablesSkeleton";

export default function VehiclesEventsReport() {
  const { data: vehicles = [], isLoading, isError, error } = Api.vehicles.useGetAllVehiclesQuery();
  const vehiclesWithEvents = vehicles.length > 0 ? vehicles.filter((vehicle) => vehicle.numberOfEvents > 0) : null;
  const vehiclesFound = isValidArrayWithData(vehiclesWithEvents);

  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (
    <TablesSkeleton isLoading={isLoading} title="Vehicle Events Report" />
  ) : vehiclesFound ? (
    <DisplayVehiclesEventsReportCreate vehiclesWithEvents={vehiclesWithEvents} />
  ) : (
    <NoRecords title="Vehicle Events Report" message="No Vehicles found, vehicle events reports cannot be generated." />
  );
}

function DisplayVehiclesEventsReportCreate({ vehiclesWithEvents }) {
  const selectInputRef = useRef();
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  const [selectedVehicles, setSelectedVehicles] = useState(null);
  const [alertInfo, setAlertInfo] = useState({ show: false });
  const [isLoadingInfo, setIsLoadingInfo] = useState({ status: false });
  const [postLocationTrackingReport] = Api.vehicles.usePostVehiclesEventsReportMutation();

  const vehicleList = useMemo(() => {
    return vehiclesWithEvents.map((vehicle) => ({ value: vehicle?.id, label: vehicle?.vehicleRegistrationNumber, id: vehicle.id, model: vehicle?.makeAndModel }));
  }, [vehiclesWithEvents]);

  const today = new Date();
  const maximumDate = today.toISOString().split("T")[0];
  const oneYearAgo = new Date(today);
  oneYearAgo.setFullYear(today.getFullYear() - 1);
  const minimumDate = oneYearAgo.toISOString().split("T")[0];

  const resetForm = () => {
    setDateRange({ startDate: "", endDate: "" });
    setSelectedVehicles(null);
    selectInputRef?.current?.select?.clearValue();
  };

  const handlePdfReport = async (e) => {
    e.preventDefault();
    const vehicles = selectedVehicles.map((vehicle) => { return { id: vehicle.id }; });
    const { startDate, endDate } = dateRange;
    const reportInfo = {
      vehicles, dateRange: {
        startDate: dateTimeMidnight(startDate),
      endDate}
    } // Create a FormData object from the form
    if (!isValidDateRange(new Date(dateRange?.startDate), new Date(dateRange?.endDate))) {
      setDateRange({ startDate: "", endDate: "" });
      setAlertInfo({ show: true, message: "Date range cannot be more than 1 year" });
      return;
    } else {
      try {
        console.log({ formData: reportInfo });
        setIsLoadingInfo({ status: true, text: "Processing Vehicle Events Report" });
        const response = await postLocationTrackingReport(reportInfo).unwrap(); // Send the FormData instance
        setIsLoadingInfo({ status: false });
        console.log({ response });

        if (response.success) {
          resetForm(); // reset form
          setAlertInfo({ show: true, message: "Events report has been generated and will be emailed to you shortly" });
        } else {
          console.log({ response });
          setAlertInfo({ show: true, message: "Report could not be generated, please try again" });
        }
      } catch (error) {
        setIsLoadingInfo({ status: false });
        console.log(error);
        setAlertInfo({ show: true, message: "Report could not be generated, please try again" });
        if (error.status === 403) {
          window.location.reload();
        }
      }
    }
  };

  const isValidDateRange = (start, end) => start <= end && end - start <= 365 * 24 * 60 * 60 * 1000;

  const handleDateRange = (e) => {
    const { name, value } = e.target;
    if (name === "startDate" && dateRange?.endDate && value > dateRange?.endDate) {
      setAlertInfo({ show: true, message: "Start date cannot be greater than end date" });
      setDateRange({ ...dateRange, startDate: "", isValid: false });
    } else if (name === "endDate" && dateRange?.startDate && value < dateRange?.startDate) {
      setAlertInfo({ show: true, message: "End date cannot be less than start date" });
      setDateRange({ ...dateRange, endDate: "", isValid: false });
    } else {
      setDateRange({ ...dateRange, [name]: value, isValid: true });
    }
  };

  const handleDateRangeChange = (newDateRange) => {
    console.log({ newDateRange });
    setDateRange(newDateRange);
  };

  const handleSelectVehicle = (data) => {
    setSelectedVehicles(data);
  };

  //console.log({ vehicles: vehiclesWithEvents });
  return (
    <Layout isLoading={isLoadingInfo.status} loadingText={isLoadingInfo.text}>
      <PrimaryHeader title="Vehicle Events Report"></PrimaryHeader>
      <AlertDialog show={alertInfo.show} alertMessage={alertInfo.message} onClick={() => setAlertInfo({ show: false })} />

      <section className="flex flex-col items-center justify-center w-full h-full px-10 py-10 ">

        <form className="flex flex-col w-full gap-4 xl:w-1/2" onSubmit={handlePdfReport}>
          <div className="h-full">
            <span className="text-primary">Select Vehicle</span>
            <InputSelect
              ref={selectInputRef}
              required={true}
              selectionOptions={vehicleList}
              setSelectedOption={handleSelectVehicle}
              allowMultiples={true}
              placeholder={"--Select vehicle(s) --"}
              name={"vehicle_id"}
              className={"text-sm font-medium"}
              allowSelectAll={true}
              itemName={"Vehicle"}
            />
          </div>
          <div>
            <DateRangeSelector onChange={handleDateRangeChange} maxDate={new Date()} />
          </div>
      

          <div className="flex justify-end">
            <button className="btn-primary" type="submit">
              Generate Report
            </button>
          </div>
        </form>
      </section>
    </Layout>
  );
}
