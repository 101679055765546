import {useEffect, useState} from "react";
import {CiCircleRemove} from "react-icons/ci";
import {FcCancel} from "react-icons/fc";
import {GiAutoRepair} from "react-icons/gi";
import {RiFindReplaceLine} from "react-icons/ri";
import {RxLinkBreak2} from "react-icons/rx";
import DataCardSection, {ILabelValuePairProps} from "../../DataCardSection";


interface IVehicleDataCardSectionProps {
  active: boolean;
  id: number;
  makeAndModel: string;
  manufactureYear: number;
  vehicleRegistrationNumber: string;
  vin: string;
  engineNumber: string;
  enableVehicleLink?: boolean;
  batteryVoltage: number;
  canUnlinkDevice?: boolean;
  canMarkInForRepairs?: boolean;
  canDisableVehicle?: boolean;
  canMarkForDeletion?: boolean;
  canInitiateVehicleTracking?: boolean;
  onMarkInForRepairs?: (id: number) => void;
  onClearInForRepairs?: (id: number) => void;
  onMarkForDeletion?: (id: number) => void;
  onClearForDeletion?: (id: number) => void;
  onUnlinkDevice?: (id: number) => void;
  onSetVehicleTrackingSms?: (id: number) => void;
}

function VehicleDataCardSection({ active, id, makeAndModel, manufactureYear, vehicleRegistrationNumber, vin, engineNumber, batteryVoltage,
  onMarkInForRepairs, onMarkForDeletion,onClearForDeletion,onClearInForRepairs, onUnlinkDevice, onSetVehicleTrackingSms,  canMarkInForRepairs = false, canMarkForDeletion = false, canUnlinkDevice = false, canDisableVehicle = false, enableVehicleLink = false, canInitiateVehicleTracking = false }: IVehicleDataCardSectionProps) {
  const [sectionData, setSectionData] = useState<ILabelValuePairProps[]>([]);
  const title = "Vehicle Information";

  useEffect(() => {
    const data: ILabelValuePairProps[] = [
      {
        label: "Active",
        value: active
      },
      {
        label: "Vehicle Registration Number",
        value: vehicleRegistrationNumber,
        copyable: true
      },
      {
        label: "Make And Model",
        value: makeAndModel
      },
      {
        label: "Manufacture Year",
        value: manufactureYear?.toString()
      },

      {
        label: "VIN",
        value: vin,
        copyable: true
      },
      {
        label: "Engine Number",
        value: engineNumber,
        copyable: true
      },
      {
        label: "Battery Voltage",
        value: batteryVoltage ? batteryVoltage.toFixed(2) : ""
      }
    ];

    if (enableVehicleLink) {
      const index = data.findIndex(data => data.label === "Vehicle Registration Number");

      if (index !== -1) {
        data[index] = {
          ...data[index],
          link: {
            to: `/vehicles/${id}`,
            title: "View Vehicle Details"
          }
        };
      }
    }

    if (canDisableVehicle) {
      const index = data.findIndex(data => data.label === "Vehicle Registration Number");
      if (index !== -1) {
        const valueAction = {
          icon: FcCancel,
          title: "Disable Vehicle",
          iconColour: "text-delete",
          onClick: () => {
            console.log("Disable Vehicle");
            alert("Vehicle Disabled");
          }
        };
        data[index] = {
          ...data[index],
          valueActions: [...(data[index].valueActions || []), ...[valueAction]]
        };
      }
    }
    if (canUnlinkDevice) {
      const index = data.findIndex(data => data.label === "Vehicle Registration Number");
      if (index !== -1 && onUnlinkDevice) {
        const valueAction = {
          icon: RxLinkBreak2,
          iconColour: "text-warn",
          title: "Unlink Device From Vehicle",
          onClick: () => onUnlinkDevice(id)
        };
        data[index] = {
          ...data[index],
          valueActions: [...(data[index].valueActions || []), ...[valueAction]]
        };
      }
    }

    if (canMarkInForRepairs) {
      const index = data.findIndex(data => data.label === "Vehicle Registration Number");
      if (index !== -1 && onMarkInForRepairs) {
        const valueAction = {
          icon: GiAutoRepair,
          title: "Mark Vehicle For Repairs",
          onClick: () => onMarkInForRepairs(id)
          }
        
        data[index] = {
          ...data[index],
          valueActions: [...(data[index].valueActions || []), ...[valueAction]]
        };
      }
    }

    if (canMarkForDeletion) {
      const index = data.findIndex(data => data.label === "Vehicle Registration Number");
      if (index !== -1 && onMarkForDeletion) {
        const valueAction = {
          icon: CiCircleRemove,
          title: "Mark Vehicle For Deletion",
          iconColour: "text-delete",
          onClick: () => onMarkForDeletion(id)
        };
        data[index] = {
          ...data[index],
          valueActions: [...(data[index].valueActions || []), ...[valueAction]]
        };
      }
    }

    if (canInitiateVehicleTracking) {
      const index = data.findIndex(data => data.label === "Vehicle Registration Number");
      if (index !== -1 && onSetVehicleTrackingSms) {
        const valueAction = {
          icon: RiFindReplaceLine,
          iconColour: "text-warn",
          title: "Initiate Vehicle Tracking",
          onClick: () => onSetVehicleTrackingSms(id)
        };
        data[index] = {
          ...data[index],
          valueActions: [...(data[index].valueActions || []), ...[valueAction]]
        };
      }
    }
    setSectionData(data);
  }, [active, id, makeAndModel, manufactureYear, vehicleRegistrationNumber, vin, engineNumber, batteryVoltage, canMarkInForRepairs, canMarkForDeletion, canUnlinkDevice, enableVehicleLink, canDisableVehicle, canInitiateVehicleTracking]);

  return (
      <DataCardSection title={title} data={sectionData} />
  );
}

export default VehicleDataCardSection;
